import {
  FunctionComponent,
  ChangeEvent,
  useState,
  useEffect,
  ReactElement,
} from "react";
import { TextField } from "@cmsgov/ds-medicare-gov";
import { limitInputLength } from "../helpers/objectUtilities";
import { useTranslate } from "../helpers/intlHooks";
import { FormattedMessage } from "react-intl";
import { TooltipWithClose } from "./TooltipWithClose";

interface SSNInputProps {
  label: string | ReactElement;
  value: SSN | undefined;
  onChange: (ssn: SSN) => void;
  errorMessage?: string;
}

export interface SSN {
  areaNumber: string;
  groupNumber: string;
  serialNumber: string;
}

export const isEmptySSN = (ssn: SSN): boolean => {
  return (
    ssn.areaNumber === "" && ssn.groupNumber === "" && ssn.serialNumber === ""
  );
};

export const isValidSsn = (ssn: SSN): boolean => {
  return (
    ssn.areaNumber.match(/^\d{3}$/) !== null &&
    ssn.groupNumber.match(/^\d{2}$/) !== null &&
    ssn.serialNumber.match(/^\d{4}$/) !== null
  );
};

export const SSNInput: FunctionComponent<SSNInputProps> = ({
  label,
  value = {
    areaNumber: "",
    groupNumber: "",
    serialNumber: "",
  },
  onChange,
  errorMessage,
}) => {
  const [ssn, setSsn] = useState(value);
  const name = "socialSecurityNumber";
  const t = useTranslate();

  useEffect(() => {
    onChange(ssn);
  }, [ssn]);

  return (
    <div>
      <label className="ds-c-label ds-c-label--input" id={name}>
        <span>{label}</span>
      </label>
      {errorMessage && (
        <span className="ds-c-field__hint ds-u-color--error" role="alert">
          {errorMessage}
        </span>
      )}
      <span className="ds-c-field__hint ds-u-font-size--base">
        <FormattedMessage
          id="ssn_input.why_am_i_being_asked"
          values={{
            socialSecurityNumber: (
              <TooltipWithClose title={t("ssn_input.tooltip_text")}>
                {t("ssn_input.tooltip_title")}
              </TooltipWithClose>
            ),
          }}
        />
      </span>
      <div className="ds-u-display--flex">
        <TextField
          size="small"
          numeric
          label="Area Number"
          aria-describedby={name}
          name={`${name}-area-number`}
          className="ds-u-margin-right--1"
          fieldClassName="ds-c-field--areaNumber"
          labelClassName="ds-u-margin-top--0 ds-u-visibility--screen-reader"
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            limitInputLength("areaNumber", 3);
            const areaNumber = event.target.value;
            setSsn({ ...ssn, areaNumber });
          }}
          value={ssn.areaNumber}
          errorMessage={errorMessage ? "" : null}
        />
        <span className="ds-c-datefield__separator">-</span>
        <TextField
          size="small"
          numeric
          label="Group Number"
          aria-describedby={name}
          name={`${name}-group-number`}
          labelClassName="ds-u-margin-top--0 ds-u-visibility--screen-reader"
          className="ds-u-margin-x--1"
          fieldClassName="ds-c-field--groupNumber"
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            limitInputLength("groupNumber", 2);
            const groupNumber = event.target.value;
            setSsn({ ...ssn, groupNumber });
          }}
          value={ssn.groupNumber}
          errorMessage={errorMessage ? "" : null}
        />
        <span className="ds-c-datefield__separator">-</span>
        <TextField
          size="medium"
          numeric
          label="Serial Number"
          aria-describedby={name}
          name={`${name}-serial-number`}
          labelClassName="ds-u-margin-top--0 ds-u-visibility--screen-reader"
          className="ds-u-margin-left--1"
          fieldClassName="ds-c-field--serialNumber"
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            limitInputLength("serialNumber", 4);
            const serialNumber = event.target.value;
            setSsn({ ...ssn, serialNumber });
          }}
          value={ssn.serialNumber}
          errorMessage={errorMessage ? "" : null}
        />
      </div>
    </div>
  );
};
