import { SvgIcon } from "@cmsgov/ds-medicare-gov";

import * as React from "react";
import { IconCommonProps } from "@cmsgov/design-system/dist/react-components/types/Icons/SvgIcon";

const defaultProps = {
  className: "",
  title: "piggy-bank-circle",
  viewBox: "0 0 35 35",
};

export const PiggyBankCircleIcon = (
  props: IconCommonProps
): React.ReactElement => {
  const iconCssClasses = `mct-c-icon--piggy-bank-circle ${
    props.className || ""
  }`;

  return (
    <SvgIcon {...defaultProps} {...props} className={iconCssClasses}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle cx="17.5" cy="17.5" r="17.5" />
        <g fill="#FFF" fillRule="nonzero">
          <path d="M22.72 15.965a.616.616 0 0 0-.615.614c0 .338.276.614.614.614a.616.616 0 0 0 .614-.614.616.616 0 0 0-.614-.614Zm4.912-1.228h-1.133a6.167 6.167 0 0 0-1.938-2.433v-2.48h-.614c-1.163 0-2.218.388-3.105 1.006.016-.13.035-.257.035-.391a4.296 4.296 0 0 0-4.299-4.299 4.296 4.296 0 0 0-4.298 4.299c0 .372.061.725.154 1.07a6.14 6.14 0 0 0-3.715 4.456h-.43a.922.922 0 0 1-.902-1.105c.084-.438.495-.737.94-.737h.038a.23.23 0 0 0 .23-.23v-.768a.23.23 0 0 0-.23-.23c-1.093 0-2.068.783-2.206 1.865a2.15 2.15 0 0 0 2.13 2.433h.307c0 2.003.975 3.765 2.456 4.885v3.097c0 .338.276.614.614.614h4.298a.616.616 0 0 0 .614-.614v-1.842h2.457v1.842c0 .338.276.614.614.614h4.298a.616.616 0 0 0 .614-.614v-3.104c.45-.346.86-.741 1.202-1.194h1.869a.616.616 0 0 0 .614-.614v-4.912a.616.616 0 0 0-.614-.614ZM16.578 7.368a3.07 3.07 0 0 1 3.01 3.685h-4.852c-.387 0-.763.046-1.132.115a2.856 2.856 0 0 1-.096-.73 3.07 3.07 0 0 1 3.07-3.07Zm10.44 12.281h-1.87c-.652.86-.652.925-1.815 1.815v3.097h-3.07v-2.456H15.35v2.456h-3.07v-3.097c-2.49-1.88-2.456-3.588-2.456-4.271a4.917 4.917 0 0 1 4.912-4.912h6.206c.649-.518 1.274-1.017 2.391-1.182v1.815c1.524 1.155 1.689 1.508 2.364 3.05h1.32v3.685Z" />
          <path d="m18.676 16.993-1.488-.478a.589.589 0 0 1-.398-.482c-.05-.366.202-.682.518-.682h.944c.204 0 .403.072.568.203a.187.187 0 0 0 .262-.025l.293-.33a.252.252 0 0 0-.019-.346 1.67 1.67 0 0 0-1.104-.423h-.036v-.691c0-.127-.092-.23-.204-.23h-.41c-.112 0-.204.103-.204.23v.69h-.032c-.63 0-1.21.458-1.361 1.146-.178.81.258 1.6.933 1.818l1.488.478a.589.589 0 0 1 .398.482c.05.365-.202.682-.518.682h-.944a.915.915 0 0 1-.568-.203.187.187 0 0 0-.262.025l-.293.33a.252.252 0 0 0 .019.346c.315.275.698.423 1.104.423h.036v.69c0 .128.092.231.204.231h.41c.112 0 .204-.103.204-.23v-.69h.032c.63 0 1.21-.459 1.361-1.146.178-.81-.258-1.6-.933-1.818Z" />
        </g>
      </g>
    </SvgIcon>
  );
};
