import {
  CoverageType,
  PlanType,
  PrescriptionFrequency,
  LowIncomeSubsidyOption,
  SortOptions,
  UserLanguage,
  DrugPricingRedactionIssue,
  PlanBenefitRedactionIssue,
  WhatToDoType,
} from "../@types";
import { TranslationKey } from "./intlHooks";

/** Constant representing Part B premium values where required to be hardcoded */
export const partBPremium = {
  "2022": 170.1,
  "2023": 164.9,
  "2024": 174.7,
  "2025": 185,
};

/** PartD Out of Pocket Threshold */
export const partDOutOfPocket = {
  "2024": 8000,
  "2025": 2000,
};

/** ----- Used for MEDIGAP messaging ----- */
/**
 * The values below work to update Medigap plan-card, plan-details, and help-drawer
 * messaging each year. Unless otherwise called out, no Phrase keys should need
 * to be changed.
 */

/**
 * Part B Health Deductible, by year
 * For Medigap, this value (or a percentage of it) is used across plans, in both
 * waiver- and non-waiver state messaging
 */
export const partBHealthDeductible = {
  "2022": 233,
  "2023": 226,
  "2024": 240,
  "2025": 257,
};

/**
 * Part A Health Deductible, by year
 * For Medigap, this value (or a percentage of it) is used across plans, in both
 * waiver- and non-waiver state messaging
 */
export const partAHealthDeductible = {
  "2022": 1556,
  "2023": 1600,
  "2024": 1632,
  "2025": 1676,
};

/**
 * Skilled Nursing Facility costs for days 21-100, by year
 * This value (or a percentage of it) is used across plans, in both waiver- and
 * non-waiver state messaging
 */
export const medigapSnfDays21to100 = {
  "2022": 194,
  "2023": 200,
  "2024": 204,
  "2025": 209.5,
};

/** Plan K Part B Coinsurance OOP limits, by year */
export const medigapPlanKOutOfPocketLimit = {
  "2022": 6620,
  "2023": 6940,
  "2024": 7060,
  "2025": 7220,
};

/**
 * Plan L Part B Coinsurance OOP limits, by year
 * (may or may not match those for Plan K / 2?)
 */
export const medigapPlanLOutOfPocketLimit = {
  "2022": medigapPlanKOutOfPocketLimit["2022"] / 2,
  "2023": medigapPlanKOutOfPocketLimit["2023"] / 2,
  "2024": medigapPlanKOutOfPocketLimit["2024"] / 2,
  "2025": medigapPlanKOutOfPocketLimit["2025"] / 2,
};

/** High deductible Plan F deductibles, by year */
export const medigapHighFDeductible = {
  "2022": 2490,
  "2023": 2700,
  "2024": 2800,
  "2025": 2875,
};

/** High deductible Plan G deductibles, by year (may or may not differ from Plan F) */
export const medigapHighGDeductible = {
  "2022": medigapHighFDeductible["2022"],
  "2023": medigapHighFDeductible["2023"],
  "2024": medigapHighFDeductible["2024"],
  "2025": medigapHighFDeductible["2025"],
};

/** ----- end for MEDIGAP messaging ----- */

export const pagination = {
  maxNumDisplayAllPages: 7,
};

/** Used to determine if a plan has at least some dental coverage */
export const dentalServices = [
  "diagnostic_services",
  "endodontics",
  "extractions",
  "nonroutine_services",
  "periodontics",
  "prosthodontics_and_other_services",
  "restorative_services",
];

/* Enrollment-related vars */
export const langChars = 2;
/* istanbul ignore next */
export const language =
  navigator.language.substring(0, langChars) === "es" ? "Spanish" : "English";

/**
 * @property count: The 'count' of how many results to return per page when querying the API.
 */
export const apiVars = {
  count: 10,
};

/**
 * @property maxPlansToCompare: The maximum allowed number of plans that can be compared.
 */
export const compareFooter = {
  minPlansToCompare: 2,
  maxPlansToCompare: 3,
  errorTimeout: 3000,
};

/**
 * Pharmacy selection constants
 */
export const pharmacySelection = {
  min: 0,
  max: 5,
  pageCount: 10,
  maxPageCount: 40,
  maxPharmacyRequests: 200,
};

/**
 * A mapping of sort-by options for the [[SearchResultsPage]]. Holds the PhraseApp ID as the value.
 */
export const sortOptionsLabels = {
  [SortOptions.DRUG_PLAN_DEDUCTIBLE]:
    "search_results_page.sort.lowest_annual_drug_deductible",
  [SortOptions.ANNUAL_DRUGS_TOTAL]:
    "search_results_page.sort.lowest_annual_drug_total",
  [SortOptions.ANNUAL_DEDUCTIBLE]:
    "search_results_page.sort.lowest_health_plan_deductible",
  [SortOptions.MONTHLY_PREMIUM]:
    "search_results_page.sort.lowest_monthly_premium",
  [SortOptions.ANNUAL_TOTAL]:
    "search_results_page.sort.lowest_drug_plus_premium_cost",
};

export const sortOptionsLabelsByPlan: {
  [x: string]: Array<{ [x: string]: string }>;
} = {
  [PlanType.PDP]: [
    {
      [SortOptions.DRUG_PLAN_DEDUCTIBLE]:
        sortOptionsLabels.DRUG_PLAN_DEDUCTIBLE,
    },
    {
      [SortOptions.ANNUAL_TOTAL]: sortOptionsLabels.ANNUAL_TOTAL,
    },
    {
      [SortOptions.MONTHLY_PREMIUM]: sortOptionsLabels.MONTHLY_PREMIUM,
    },
  ],
  [PlanType.MAPD]: [
    {
      [SortOptions.DRUG_PLAN_DEDUCTIBLE]:
        sortOptionsLabels.DRUG_PLAN_DEDUCTIBLE,
    },
    {
      [SortOptions.ANNUAL_DEDUCTIBLE]: sortOptionsLabels.ANNUAL_DEDUCTIBLE,
    },
    {
      [SortOptions.ANNUAL_TOTAL]: sortOptionsLabels.ANNUAL_TOTAL,
    },
    {
      [SortOptions.MONTHLY_PREMIUM]: sortOptionsLabels.MONTHLY_PREMIUM,
    },
  ],
};

export const prescriptionFrequencyLabels: Record<
  PrescriptionFrequency,
  TranslationKey
> = {
  [PrescriptionFrequency.DAYS_30]: "prescription.frequency.days_30",
  [PrescriptionFrequency.DAYS_60]: "prescription.frequency.days_60",
  [PrescriptionFrequency.DAYS_90]: "prescription.frequency.days_90",
  [PrescriptionFrequency.DAYS_180]: "prescription.frequency.days_180",
  [PrescriptionFrequency.DAYS_360]: "prescription.frequency.days_360",
};

export const coverageWizardUrl = (language: UserLanguage): string => {
  let origin = window.location.origin;
  origin = origin.replace(/localhost/, "dev1.medicare.gov");
  if (language === UserLanguage.SPANISH) {
    origin = origin.replace(/www/, "es");
    origin = origin.replace(/imp/, "es.imp");
  }
  return `${origin}/medicarecoverageoptions/#home`;
};

export const abstractBenefitTierNames = {
  COST_SHARE_TIER_0_DOLLAR_DRUGS: "COST_SHARE_TIER_0_DOLLAR_DRUGS",
  COST_SHARE_TIER_BRAND_DRUGS: "COST_SHARE_TIER_BRAND_DRUGS",
  COST_SHARE_TIER_BRAND_NAME_DRUGS: "COST_SHARE_TIER_BRAND_NAME_DRUGS",
  COST_SHARE_TIER_BRAND: "COST_SHARE_TIER_BRAND",
  COST_SHARE_TIER_EXCLUDED_DRUGS: "COST_SHARE_TIER_EXCLUDED_DRUGS",
  COST_SHARE_TIER_GENERIC_DRUGS: "COST_SHARE_TIER_GENERIC_DRUGS",
  COST_SHARE_TIER_GENERIC: "COST_SHARE_TIER_GENERIC",
  COST_SHARE_TIER_INJECTABLE_DRUGS: "COST_SHARE_TIER_INJECTABLE_DRUGS",
  COST_SHARE_TIER_NON_MEDICARE_OTC_DRUGS:
    "COST_SHARE_TIER_NON_MEDICARE_OTC_DRUGS",
  COST_SHARE_TIER_NON_MEDICARE_RX_DRUGS:
    "COST_SHARE_TIER_NON_MEDICARE_RX_DRUGS",
  COST_SHARE_TIER_NON_MEDICARE_RX_OTC_DRUGS:
    "COST_SHARE_TIER_NON_MEDICARE_RX_OTC_DRUGS",
  COST_SHARE_TIER_NON_PREFERRED_BRAND_DRUGS:
    "COST_SHARE_TIER_NON_PREFERRED_BRAND_DRUGS",
  COST_SHARE_TIER_NON_PREFERRED_BRAND: "COST_SHARE_TIER_NON_PREFERRED_BRAND",
  COST_SHARE_TIER_NON_PREFERRED_DRUG: "COST_SHARE_TIER_NON_PREFERRED_DRUG",
  COST_SHARE_TIER_PREFERRED_BRAND_DRUGS:
    "COST_SHARE_TIER_PREFERRED_BRAND_DRUGS",
  COST_SHARE_TIER_PREFERRED_BRAND: "COST_SHARE_TIER_PREFERRED_BRAND",
  COST_SHARE_TIER_PREFERRED_GENERIC_DRUGS:
    "COST_SHARE_TIER_PREFERRED_GENERIC_DRUGS",
  COST_SHARE_TIER_PREFERRED_GENERIC: "COST_SHARE_TIER_PREFERRED_GENERIC",
  COST_SHARE_TIER_PREFERRED_SPECIALTY_TIER:
    "COST_SHARE_TIER_PREFERRED_SPECIALTY_TIER",
  COST_SHARE_TIER_SELECT_CARE_DRUGS: "COST_SHARE_TIER_SELECT_CARE_DRUGS",
  COST_SHARE_TIER_SELECT_DIABETIC_DRUGS:
    "COST_SHARE_TIER_SELECT_DIABETIC_DRUGS",
  COST_SHARE_TIER_SPECIALTY_TIER: "COST_SHARE_TIER_SPECIALTY_TIER",
  COST_SHARE_TIER_SUPPLEMENTAL_BRAND_AND_GENERIC_DRUGS:
    "COST_SHARE_TIER_SUPPLEMENTAL_BRAND_AND_GENERIC_DRUGS",
  COST_SHARE_TIER_SUPPLEMENTAL_DRUGS: "COST_SHARE_TIER_SUPPLEMENTAL_DRUGS",
  COST_SHARE_TIER_VACCINES: "COST_SHARE_TIER_VACCINES",
} as const;

export const abstractBenefitTierTranslations = {
  [abstractBenefitTierNames.COST_SHARE_TIER_0_DOLLAR_DRUGS]:
    "abstract_benefits.cost_share_tier.0_dollar_drugs",
  [abstractBenefitTierNames.COST_SHARE_TIER_BRAND_DRUGS]:
    "abstract_benefits.cost_share_tier.brand_drugs",
  [abstractBenefitTierNames.COST_SHARE_TIER_BRAND_NAME_DRUGS]:
    "abstract_benefits.cost_share_tier.brand_name_drugs",
  [abstractBenefitTierNames.COST_SHARE_TIER_BRAND]:
    "abstract_benefits.cost_share_tier.brand",
  [abstractBenefitTierNames.COST_SHARE_TIER_EXCLUDED_DRUGS]:
    "abstract_benefits.cost_share_tier.excluded_drugs",
  [abstractBenefitTierNames.COST_SHARE_TIER_GENERIC_DRUGS]:
    "abstract_benefits.cost_share_tier.generic_drugs",
  [abstractBenefitTierNames.COST_SHARE_TIER_GENERIC]:
    "abstract_benefits.cost_share_tier.generic",
  [abstractBenefitTierNames.COST_SHARE_TIER_INJECTABLE_DRUGS]:
    "abstract_benefits.cost_share_tier.injectable_drugs",
  [abstractBenefitTierNames.COST_SHARE_TIER_NON_MEDICARE_OTC_DRUGS]:
    "abstract_benefits.cost_share_tier.non_medicare_otc_drugs",
  [abstractBenefitTierNames.COST_SHARE_TIER_NON_MEDICARE_RX_DRUGS]:
    "abstract_benefits.cost_share_tier.non_medicare_rx_drugs",
  [abstractBenefitTierNames.COST_SHARE_TIER_NON_MEDICARE_RX_OTC_DRUGS]:
    "abstract_benefits.cost_share_tier.non_medicare_rx_otc_drugs",
  [abstractBenefitTierNames.COST_SHARE_TIER_NON_PREFERRED_BRAND_DRUGS]:
    "abstract_benefits.cost_share_tier.non_preferred_brand_drugs",
  [abstractBenefitTierNames.COST_SHARE_TIER_NON_PREFERRED_BRAND]:
    "abstract_benefits.cost_share_tier.non_preferred_brand",
  [abstractBenefitTierNames.COST_SHARE_TIER_NON_PREFERRED_DRUG]:
    "abstract_benefits.cost_share_tier.non_preferred_drug",
  [abstractBenefitTierNames.COST_SHARE_TIER_PREFERRED_BRAND_DRUGS]:
    "abstract_benefits.cost_share_tier.preferred_brand_drugs",
  [abstractBenefitTierNames.COST_SHARE_TIER_PREFERRED_BRAND]:
    "abstract_benefits.cost_share_tier.preferred_brand",
  [abstractBenefitTierNames.COST_SHARE_TIER_PREFERRED_GENERIC_DRUGS]:
    "abstract_benefits.cost_share_tier.preferred_generic_drugs",
  [abstractBenefitTierNames.COST_SHARE_TIER_PREFERRED_GENERIC]:
    "abstract_benefits.cost_share_tier.preferred_generic",
  [abstractBenefitTierNames.COST_SHARE_TIER_PREFERRED_SPECIALTY_TIER]:
    "abstract_benefits.cost_share_tier.preferred_specialty_tier",
  [abstractBenefitTierNames.COST_SHARE_TIER_SELECT_CARE_DRUGS]:
    "abstract_benefits.cost_share_tier.select_care_drugs",
  [abstractBenefitTierNames.COST_SHARE_TIER_SELECT_DIABETIC_DRUGS]:
    "abstract_benefits.cost_share_tier.select_diabetic_drugs",
  [abstractBenefitTierNames.COST_SHARE_TIER_SPECIALTY_TIER]:
    "abstract_benefits.cost_share_tier.specialty_tier",
  [abstractBenefitTierNames.COST_SHARE_TIER_SUPPLEMENTAL_BRAND_AND_GENERIC_DRUGS]:
    "abstract_benefits.cost_share_tier.supplemental_brand_and_generic_drugs",
  [abstractBenefitTierNames.COST_SHARE_TIER_SUPPLEMENTAL_DRUGS]:
    "abstract_benefits.cost_share_tier.supplemental_drugs",
  [abstractBenefitTierNames.COST_SHARE_TIER_VACCINES]:
    "abstract_benefits.cost_share_tier.vaccines",
};

export const optionalPackageBenefits = {
  SUPPLEMENTAL_NOT_AVAILABLE: "optional_packages.benefit.not_available",
  SUPPLEMENTAL_INPATIENT_HOSPITAL_COVERAGE:
    "optional_packages.benefit.inpatient_hospital_coverage",
  SUPPLEMENTAL_MENTAL_HEALTH_SERVICES:
    "optional_packages.benefit.mental_health_services",
  SUPPLEMENTAL_SKILLED_NURSING_FACILITY:
    "optional_packages.benefit.skilled_nursing_facility",
  SUPPLEMENTAL_CARDIAC_REHABILITATION_SERVICES:
    "optional_packages.benefit.cardiac_rehabilitation_services",
  SUPPLEMENTAL_INTENSIVE_CARDIAC_REHABILITATION_SERVICES:
    "optional_packages.benefit.intensive_cardiac_rehabilitation_services",
  SUPPLEMENTAL_PULMONARY_REHABILITATION_SERVICES:
    "optional_packages.benefit.pulmonary_rehabilitation_services",
  SUPPLEMENTAL_EMERGENCY: "optional_packages.benefit.emergency",
  SUPPLEMENTAL_URGENT_CARE: "optional_packages.benefit.urgent_care",
  SUPPLEMENTAL_WORLDWIDE_EMERGENCY:
    "optional_packages.benefit.worldwide_emergency",
  SUPPLEMENTAL_PARTIAL_HOSPITALIZATION:
    "optional_packages.benefit.partial_hospitalization",
  SUPPLEMENTAL_HOME_HEALTH_CARE: "optional_packages.benefit.home_health_care",
  SUPPLEMENTAL_PRIMARY: "optional_packages.benefit.primary",
  SUPPLEMENTAL_CHIROPRACTIC_CARE: "optional_packages.benefit.chiropractic_care",
  SUPPLEMENTAL_OCCUPATIONAL_THERAPY:
    "optional_packages.benefit.occupational_therapy",
  SUPPLEMENTAL_SPECIALIST: "optional_packages.benefit.specialist",
  SUPPLEMENTAL_OUTPATIENT_THERAPY:
    "optional_packages.benefit.outpatient_therapy",
  SUPPLEMENTAL_FOOT_CARE: "optional_packages.benefit.foot_care",
  SUPPLEMENTAL_OTHER_HEALTH_CARE_PROFESSIONAL:
    "optional_packages.benefit.other_health_care_professional",
  SUPPLEMENTAL_OUTPATIENT_THERAPY_PSYCHIATRIST:
    "optional_packages.benefit.outpatient_therapy_psychiatrist",
  SUPPLEMENTAL_PHYSICAL_SPEECH_LANGUAGE_THERAPY_VISIT:
    "optional_packages.benefit.physical_speech_language_therapy_visit",
  SUPPLEMENTAL_DIAGNOSTIC_PROCEDURES:
    "optional_packages.benefit.diagnostic_procedures",
  SUPPLEMENTAL_DIAGNOSTIC_RADIOLOGY:
    "optional_packages.benefit.diagnostic_radiology",
  SUPPLEMENTAL_OUTPATIENT_HOSPITAL:
    "optional_packages.benefit.outpatient_hospital",
  SUPPLEMENTAL_AMBULATORY_SURGICAL_CENTER:
    "optional_packages.benefit.ambulatory_surgical_center",
  SUPPLEMENTAL_OUTPATIENT_SUBSTANCE_ABUSE:
    "optional_packages.benefit.outpatient_substance_abuse",
  SUPPLEMENTAL_BLOOD: "optional_packages.benefit.blood",
  SUPPLEMENTAL_AMBULANCE: "optional_packages.benefit.ambulance",
  SUPPLEMENTAL_TRANSPORTATION: "optional_packages.benefit.transportation",
  SUPPLEMENTAL_DURABLE_MEDICAL_EQUIPMENT:
    "optional_packages.benefit.durable_medical_equipment",
  SUPPLEMENTAL_PROSTHETICS: "optional_packages.benefit.prosthetics",
  SUPPLEMENTAL_DIABETES_SUPPLIES: "optional_packages.benefit.diabetes_supplies",
  SUPPLEMENTAL_RENAL_DIALYSIS: "optional_packages.benefit.renal_dialysis",
  SUPPLEMENTAL_ACUPUNCTURE: "optional_packages.benefit.acupuncture",
  SUPPLEMENTAL_OVER_THE_COUNTER_ITEMS:
    "optional_packages.benefit.over_the_counter_items",
  SUPPLEMENTAL_MEAL_BENEFIT: "optional_packages.benefit.meal_benefit",
  SUPPLEMENTAL_OTHER_1: "optional_packages.benefit.other_1",
  SUPPLEMENTAL_OTHER_2: "optional_packages.benefit.other_2",
  SUPPLEMENTAL_OTHER_3: "optional_packages.benefit.other_3",
  SUPPLEMENTAL_DUAL_ELIGIBLE_SNP_WITH_HIGHLY_INTEGRATED_SERVICES:
    "optional_packages.benefit.dual_eligible_snp_with_highly_integrated_services",
  SUPPLEMENTAL_PREVENTIVE_CARE: "optional_packages.benefit.preventive_care",
  SUPPLEMENTAL_ANNUAL_PHYSICAL_EXAM:
    "optional_packages.benefit.annual_physical_exam",
  SUPPLEMENTAL_WELLNESS_PROGRAMS: "optional_packages.benefit.wellness_programs",
  SUPPLEMENTAL_KIDNEY_DISEASE_EDUCATION_SERVICES:
    "optional_packages.benefit.kidney_disease_education_services",
  SUPPLEMENTAL_OTHER_MEDICARE_COVERED_PREVENTIVE_SERVICES:
    "optional_packages.benefit.other_medicare_covered_preventive_services",
  SUPPLEMENTAL_MEDICARE_PART_B_DRUGS:
    "optional_packages.benefit.medicare_part_b_drugs",
  SUPPLEMENTAL_PREVENTIVE_DENTAL_SERVICES:
    "optional_packages.benefit.preventive_dental_services",
  SUPPLEMENTAL_COMPREHENSIVE_DENTAL_SERVICES:
    "optional_packages.benefit.comprehensive_dental_services",
  SUPPLEMENTAL_EYE_EXAMS: "optional_packages.benefit.eye_exams",
  SUPPLEMENTAL_EYEWEAR: "optional_packages.benefit.eyewear",
  SUPPLEMENTAL_HEARING_EXAMS: "optional_packages.benefit.hearing_exams",
  SUPPLEMENTAL_HEARING_AIDS: "optional_packages.benefit.hearing_aids",
  SUPPLEMENTAL_PRESCRIPTION_DRUGS_COST_PLANS_ONLY:
    "optional_packages.benefit.prescription_drugs_cost_plans_only",
  SUPPLEMENTAL_POS_OPTION: "optional_packages.benefit.pos_option",
  SUPPLEMENTAL_VISITOR_TRAVEL_PROGRAM:
    "optional_packages.benefit.visitor_travel_program",
};

/**
 * For URLs that start with `www.ssa.gov`.
 */
export const ssaGovUrls = {
  /**
   * Social Security Disability Insurance (SSDI) Benefit website
   */
  ssdiBenefits: {
    [UserLanguage.ENGLISH]: "https://www.ssa.gov/benefits/disability/",
    [UserLanguage.SPANISH]:
      "https://www.ssa.gov/espanol/beneficios/incapacidad/",
  },
  /** Sign up for Medicare */
  signUp: {
    [UserLanguage.ENGLISH]: "https://www.ssa.gov/medicare/sign-up/",
    [UserLanguage.SPANISH]: "https://www.ssa.gov/es/medicare/sign-up/",
  },
};

export const linkToSHIPHelp = "https://www.shiphelp.org/";
export const linkToPaxlovidProgram = "https://www.paxlovid.com/paxcess";

export const authenticatedMGovUrls = {
  dashboard: "medicare.gov/my/home",
};

export const maxLisDrugDeductibles = {
  "2020": 89,
  "2021": 92,
  "2022": 99,
  "2023": 104,
};

export const medigapLink =
  "https://www.medicare.gov/medigap-supplemental-insurance-plans";

export const mailOrderNpi = "mail-order";

export const emdash = "—";

export const medigapPath = "medigap-supplemental-insurance-plans";
export const planComparePath = "plan-compare";
export const slsCallbackPath = "sls-callback";

export const TealiumConversions = {
  [LowIncomeSubsidyOption.Medicaid]: "Medicaid",
  [LowIncomeSubsidyOption.SSI]: "SSI",
  [LowIncomeSubsidyOption.MSP]: "MSP",
  [LowIncomeSubsidyOption.SS]: "Social Security",
  [LowIncomeSubsidyOption.Unsure]: "Not Sure",
  [LowIncomeSubsidyOption.None]: "Don't Get Help",
  [PlanType.MAPD]: "Medicare Advantage",
  [PlanType.PDP]: "Part D",
  [PlanType.MEDIGAP]: "Medigap",
  [CoverageType.MAPD]: "MAPD",
  [CoverageType.PDP]: "Part D",
  [CoverageType.MedigapPolicy]: "Medigap",
  // `CoverageType.learnMore` is also being used as a proxy for `WhatToDoType.learnMore`,
  // which is the same value: "LEARN_MORE"
  [CoverageType.learnMore]: "Learn More",
  [WhatToDoType.choosePlans]: "Choose Plans",
};

export const StickyNavE2EClassName = "e2e-sticky-nav";

export const DrugPricingRedactionIssues = Object.values(
  DrugPricingRedactionIssue
);

export const PlanBenefitRedactionIssues = Object.keys(
  PlanBenefitRedactionIssue
) as Array<keyof typeof PlanBenefitRedactionIssue>;

export const coverageTypeIdMappings = {
  [CoverageType.MAPD]: "what-coverage-mapd",
  [CoverageType.PDP]: "what-coverage-pdp",
  [CoverageType.MedigapPolicy]: "what-coverage-medigap",
};

/** duration to display toast, in milliseconds */
export const DEFAULT_TOAST_DURATION = 5000;
