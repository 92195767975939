import {
  format,
  isBefore,
  isFuture,
  isValid,
  parseISO,
  subYears,
  isLeapYear,
  isMatch,
} from "date-fns";
import enUS from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import { MCTDate, QueryStringLanguage, UserLanguage } from "../@types";
import {
  inputIsBetween,
  containsLetters,
  limitInputValueLength,
} from "./objectUtilities";
import { userToQueryStringLangMap } from "./urlHelpers";

export const dateFromMCTDate = (date: MCTDate): Date => {
  return new Date(
    parseInt(date.year, 10),
    parseInt(date.month, 10) - 1,
    parseInt(date.day, 10)
  );
};

export const isEmptyDate = (value: MCTDate): boolean => {
  return !value.day && !value.month && !value.year;
};

export const isNotValid = ({
  value,
  low,
  high,
  strict,
}: {
  value: string;
  low: number;
  high: number;
  strict?: boolean;
}): boolean => {
  if (!value && strict) {
    return true;
  } else if (!value) {
    return false;
  }
  if (containsLetters(value)) return true;
  if (!inputIsBetween(Number(value), low, high)) return true;
  return false;
};

const currentYear = new Date().getFullYear();
export const minCurrentYear = currentYear - 120;
export const maxCurrentYear = currentYear + 1;

export const isInvalidDatePart = {
  day: (value: string, strict = false): boolean =>
    isNotValid({ value, low: 1, high: 31, strict }),
  month: (value: string, strict = false): boolean =>
    isNotValid({ value, low: 1, high: 12, strict }),
  year: (value: string, strict = false): boolean => {
    if (strict && value.length < 4) return true;
    else if (value.length < 4) return false;
    return isNotValid({
      value,
      low: minCurrentYear,
      high: maxCurrentYear,
      strict,
    });
  },
};

export const dateValidator = (
  mctDate: MCTDate
): { validDay: boolean; validMonth: boolean; validYear: boolean } => {
  const strict = true;
  const dayIsValid = !isInvalidDatePart.day(mctDate.day, strict);
  const monthIsValid = !isInvalidDatePart.month(mctDate.month, strict);
  const yearIsValid = !isInvalidDatePart.year(mctDate.year, strict);

  const thirtyOneDayMonths = ["1", "3", "5", "7", "8", "10", "12"];
  const isRealDay =
    Number(mctDate.day) < 31 ||
    thirtyOneDayMonths.includes(mctDate.month.replace(/^0/, ""));
  const februaryIssue =
    ["2", "02"].includes(mctDate.month) &&
    (Number(mctDate.day) > 29 ||
      (!isLeapYear(new Date(parseInt(mctDate.year, 10), 7, 7)) &&
        Number(mctDate.day) === 29));

  return {
    validDay: dayIsValid && isRealDay && !februaryIssue,
    validMonth: monthIsValid,
    validYear: yearIsValid,
  };
};

export const isValidDate = (mctDate: MCTDate): boolean => {
  const { validDay, validMonth, validYear } = dateValidator(mctDate);

  return validDay && validMonth && validYear;
};

export const limitDateInputValueLength = ({
  inputVal,
  limit,
}: {
  inputVal: string;
  limit: number;
}): string => {
  const numericVals = inputVal.replace(/\D/g, "");
  return limitInputValueLength({ inputVal: numericVals, limit });
};

export const isValidBirthdate = (dob: MCTDate): boolean => {
  if (!isValidDate(dob)) return false;

  const dobDate = dateFromMCTDate(dob);

  if (!isValid(dobDate)) return false;

  if (isFuture(dobDate)) return false;

  if (isBefore(dobDate, subYears(new Date(), 130))) return false;

  return true;
};

export const dateFormatter = ({ month, day, year }: MCTDate): MCTDate => {
  return {
    month: month.replace(/\D+/g, "").substring(0, 2),
    day: day.replace(/\D+/g, "").substring(0, 2),
    year: year.replace(/\D+/g, "").substring(0, 4),
  };
};

export const defaultDateFormat = "M/d/yyyy";

/**
 * Format a date in a particular format for a particular language
 *
 * Reference for `fmt` strings:
 * @see https://www.unicode.org/reports/tr35/tr35-dates.html#table-date-field-symbol-table
 *
 * @returns A formatted date or an empty string (on error). Perform checks on the
 * date value passed in or the returned value and conditionally render, accordingly
 */
export const formatDate = (
  date: string | number | Date,
  fmt: string = defaultDateFormat,
  lang?: UserLanguage
): string => {
  try {
    const localeString = window.navigator.language.substring(0, 2);
    const dateToUse = typeof date === "string" ? parseISO(date) : date;
    let locale = enUS;
    if (lang) {
      if (lang === UserLanguage.SPANISH) {
        locale = es;
      }
    } else {
      if (localeString === "es") {
        locale = es;
      }
    }
    return format(dateToUse, fmt, { locale });
  } catch {
    return "";
  }
};

export const formatMCTDate = (date: MCTDate | undefined): string => {
  if (!date || !date.day || !date.month || !date.year) {
    return "N/A";
  }

  return `${date.month}/${date.day}/${date.year}`;
};

export const MCTDateFromString = (date: string): MCTDate => {
  if (date.match(/\d{4}-\d{2}-\d{2}/)) {
    return {
      month: date.substring(5, 7),
      day: date.substring(8),
      year: date.substring(0, 4),
    };
  }
  return { day: "", month: "", year: "" };
};

/**
 * Verifies the date string returned from the API.
 * @param dateStr date string returned from the API. Should be in YYYY-MM-DD format.
 * @returns true if the date has valid format, or false if invalid or empty.
 */
export const isValidApiDate = (dateStr: string): boolean => {
  if (dateStr) {
    return isMatch(dateStr, "yyyy-MM-dd");
  }
  return false;
};

/**
 * Returns a proper locale (`Intl.UnicodeBCP47LocaleIdentifier`) from the `lang` query param,
 * used for formatting translated date name strings (e.g., month names) from `Date`s.
 * Uppercases the region portion of the `lang` value
 *
 * E.g.,`en-us` => `en-US`
 */
export const getLocaleFromUrlLang = (
  lang: QueryStringLanguage
): Intl.UnicodeBCP47LocaleIdentifier => {
  const [langPart, regionPart] = lang.split("-");
  return [langPart, regionPart.toUpperCase()].join("-");
};

/**
 * Returns a proper locale (`Intl.UnicodeBCP47LocaleIdentifier`) from the `AppState.language` value,
 * used for formatting translated date name strings (e.g., month names) from `Date`s.
 *
 * E.g.,`en` => `en-US`
 */
export const getLocaleFromUserLang = (
  lang: UserLanguage
): Intl.UnicodeBCP47LocaleIdentifier =>
  getLocaleFromUrlLang(userToQueryStringLangMap[lang]);
