import { lazy } from "react";

export const CoverageWizardInfoPage = lazy(
  () => import("../pages/CoverageWizard/CoverageWizardInfoPage")
);

export const CoverageWizardLandingPage = lazy(
  () => import("../pages/CoverageWizard/CoverageWizardLandingPage")
);

export const CoverageWizardOptionsPage = lazy(
  () => import("../pages/CoverageWizard/CoverageWizardOptionsPage")
);

export const CSRLandingPage = lazy(() =>
  import("../pages/CSRLandingPage").then(module => ({
    default: module.CSRLandingPage,
  }))
);

export const DrugSearchPreferencesPage = lazy(
  () => import("../pages/DrugSearchPreferencesPage/DrugSearchPreferencesPage")
);

export const EnrollmentFormPage = lazy(
  () => import("../pages/EnrollmentFormPage/EnrollmentFormPage")
);

export const FindPlansNowPage = lazy(() =>
  import("../pages/FindPlansNowPage/FindPlansNowPage").then(module => ({
    default: module.FindPlansNowPage,
  }))
);

export const InNetworkPharmaciesPage = lazy(() =>
  import("../pages/SelectPharmaciesPage/InNetworkPharmaciesPage").then(
    module => ({
      default: module.InNetworkPharmaciesPage,
    })
  )
);

export const LandingPage = lazy(
  () => import("../pages/LandingPage/LandingPage")
);

export const LISQuestionsPage = lazy(() =>
  import("../pages/LISQuestionsPage/LISQuestionsPage").then(module => ({
    default: module.LISQuestionsPage,
  }))
);

export const LoadingPage = lazy(() =>
  import("../pages/LoadingPage").then(module => ({
    default: module.LoadingPage,
  }))
);

export const LogoutPage = lazy(() =>
  import("../pages/LogoutPage/LogoutPage").then(module => ({
    default: module.LogoutPage,
  }))
);

export const MaintenancePage = lazy(() =>
  import("../components/MaintenancePage").then(module => ({
    default: module.MaintenancePage,
  }))
);

export const ManagePharmaciesPage = lazy(
  () => import("../pages/ManagePharmaciesPage/ManagePharmaciesPage")
);

export const ManagePrescriptionsPage = lazy(
  () => import("../pages/ManagePrescriptionsPage/ManagePrescriptionsPage")
);

export const MedigapLandingPage = lazy(
  () => import("../pages/Medigap/MedigapLandingPage/MedigapLandingPage")
);

export const MedigapPlanDetailsPage = lazy(() =>
  import("../pages/Medigap/MedigapPlanDetailsPage/MedigapPlanDetailsPage").then(
    module => ({ default: module.MedigapPlanDetailsPage })
  )
);

export const MedigapPlans = lazy(
  () => import("../pages/Medigap/Plans/MedigapPlans")
);

export const MedigapPoliciesPage = lazy(() =>
  import("../pages/Medigap/Policies/MedigapPoliciesPage").then(module => ({
    default: module.MedigapPoliciesPage,
  }))
);

export const NewToMedicarePage = lazy(() =>
  import("../pages/NewToMedicarePage/NewToMedicarePage").then(module => ({
    default: module.NewToMedicarePage,
  }))
);

export const PaceLandingPage = lazy(
  () => import("../pages/Pace/PaceLandingPage")
);

export const PacePlansPage = lazy(() => import("../pages/Pace/PacePlansPage"));

export const PapLandingPage = lazy(() => import("../pages/PAP/PapLandingPage"));

export const PapPlansPage = lazy(() => import("../pages/PAP/PapPlansPage"));

export const PDEPage = lazy(() => import("../pages/PDEPage/PDEPage"));

export const PlanComparePage = lazy(() =>
  import("../pages/PlanComparePage/PlanComparePage").then(module => ({
    default: module.PlanComparePage,
  }))
);

export const PlanDetailsPage = lazy(() =>
  import("../pages/PlanDetailsPage/PlanDetailsPage").then(module => ({
    default: module.PlanDetailsPage,
  }))
);

export const PlanPreviewPage = lazy(
  () => import("../pages/PlanPreviewPage/PlanPreviewPage")
);

export const QuestionRoutingPage = lazy(
  () => import("../pages/QuestionRoutingPage/QuestionRoutingPage")
);

export const SanctionedPlansPage = lazy(
  () => import("../pages/SanctionedPlansPage/SanctionedPlansPage")
);

export const SearchResultsPage = lazy(() =>
  import("../pages/SearchResultsPage/SearchResultsPage").then(module => ({
    default: module.SearchResultsPage,
  }))
);

export const SelectPharmaciesPage = lazy(() =>
  import("../pages/SelectPharmaciesPage/SelectPharmaciesPage").then(module => ({
    default: module.SelectPharmaciesPage,
  }))
);

export const SpapLandingPage = lazy(
  () => import("../pages/SPAP/SpapLandingPage")
);

export const SpapPlansPage = lazy(() => import("../pages/SPAP/SpapPlansPage"));

export const SummaryPage = lazy(
  () => import("../pages/SummaryPage/SummaryPage")
);

export const VersionPage = lazy(
  () => import("../pages/VersionPage/VersionPage")
);

export const CSRGuestAccessPage = lazy(() =>
  import("../pages/CSRGuestAccessPage").then(module => ({
    default: module.CSRGuestAccessPage,
  }))
);
