import { ReactNode, Suspense, useEffect, useState } from "react";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactQueryProductionDevtools } from "./ReactQueryProductionDevtools";
import { DISABLE_REACT_QUERY_DEVTOOLS } from "../../config";
import { usePlaywrightEnv } from "../../helpers/testUtils/usePlaywrightEnv";
import { logError } from "../../helpers/errors";

/** Wrapper for React Query */
export const QueryProvider = ({ children }: { children?: ReactNode }) => {
  // * State
  const [showProductionDevtools, setShowProductionDevtools] = useState(false);
  const playwrightEnv = usePlaywrightEnv();

  // * Query Client
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error, query) => {
        if (query.meta?.errorMessage) {
          logError(String(query.meta.errorMessage), error);
        }
      },
    }),
  });

  // * Effects

  /**
   * calling `window.toggleDevtools()` will download the devtools bundle and show them.
   * @see https://tanstack.com/query/v5/docs/framework/react/devtools
   **/
  useEffect(() => {
    // this is disabled to allow us to add a function to window.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).toggleDevtools = () =>
      setShowProductionDevtools(old => !old);
  }, []);

  const disableReactQueryDevtools =
    // disabled for playwright
    playwrightEnv.DISABLE_REACT_QUERY_DEVTOOLS ||
    // env variable must be not be enabled
    DISABLE_REACT_QUERY_DEVTOOLS;

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {!disableReactQueryDevtools && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
      {showProductionDevtools && (
        <Suspense fallback={null}>
          <ReactQueryProductionDevtools />
        </Suspense>
      )}
    </QueryClientProvider>
  );
};
