import { useFlags } from "launchdarkly-react-client-sdk";
import {
  DrivingDistanceInfo,
  DrivingDistanceUnit,
  UseTranslateTypeStrict,
} from "../@types";
import { useTypedTranslate } from "./intlHooks";

/**
 * @param pharmacy - any object with `DrivingDistanceInfo` interface.
 * @param abbreviate - use "mi" and "ft" instead of "miles" and "feet"
 * @returns {string | undefined} "0.5 miles" or undefined
 */
export const getPharmacyDrivingDistanceDisplay = (
  t: UseTranslateTypeStrict,
  pharmacy?: DrivingDistanceInfo,
  abbreviate?: boolean
): string | undefined => {
  const { driving_distance, driving_distance_units } = pharmacy || {
    driving_distance: null,
    driving_distance_units: null,
  };
  // * Functions
  const getDisplayUnit = (distance: number, unit: DrivingDistanceUnit) => {
    return abbreviate
      ? t(`unit.${unit}.abbr`)
      : distance === 1
        ? t(`unit.${unit}.singular`)
        : t(`unit.${unit}`);
  };
  // * Return
  switch (driving_distance_units) {
    case "miles": {
      if (driving_distance === null) return;
      const roundedMiles = Math.round(driving_distance * 10) / 10;
      const stringMiles =
        // remove leading zeros
        roundedMiles > 0
          ? `${roundedMiles}`.replace(/^0+/, "")
          : `${roundedMiles}`;
      return `${stringMiles} ${getDisplayUnit(roundedMiles, "miles")}`;
    }
    case "feet": {
      if (driving_distance === null) return;
      const roundedFeet = Math.round(driving_distance);
      return `${roundedFeet} ${getDisplayUnit(roundedFeet, "feet")}`;
    }
    default:
      return;
  }
};

/**
 * @param pharmacy - any object with `DrivingDistanceInfo` interface.
 * @param abbreviate - use "mi" and "ft" instead of "miles" and "feet"
 * @returns {string | undefined} "0.5 miles" or undefined
 */
export const useDistanceFromPharmacy = (
  pharmacy?: DrivingDistanceInfo,
  abbreviate?: boolean
): string | undefined => {
  // * Translate
  const t = useTypedTranslate();

  // * Flags
  const { tmpFeEnableCmasMapPharmacyDistance } = useFlags();
  if (!tmpFeEnableCmasMapPharmacyDistance) return;

  return getPharmacyDrivingDistanceDisplay(t, pharmacy, abbreviate);
};
