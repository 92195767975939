import { FC } from "react";
import Routes from "../../../routes";
import { AlertMessageConditions, AlertMessageContext } from "../types";
import { AlertMessageLink } from "../../../../components/AlertMessage/AlertMessageLink";
import { AlertMessageLinkWrapperProps } from "./types";

export const DefaultNoSavedPharmaciesLink: FC<
  AlertMessageLinkWrapperProps & {
    alertMessageContext: AlertMessageContext;
  }
> = ({ children, alertMessageContext, ...props }) => {
  return (
    <AlertMessageLink
      {...props}
      to={{
        pathname: Routes.inNetworkPharmacy.replace(
          ":id",
          alertMessageContext?.planId || ""
        ),
        state: alertMessageContext?.routerState,
        search: alertMessageContext?.search,
      }}
      condition={AlertMessageConditions.NO_SAVED_PHARMACIES}
    >
      {children}
    </AlertMessageLink>
  );
};

export const NoSavedPharmaciesLink: FC<
  AlertMessageLinkWrapperProps & {
    alertMessageContext: AlertMessageContext;
  }
> = ({ children, alertMessageContext, ...props }) => {
  const hasPlan = !!alertMessageContext.planId;
  const inNetworkPharmacyFinderLink = Routes.inNetworkPharmacy.replace(
    ":id",
    alertMessageContext?.planId || ""
  );
  const summaryPageAddPharmaciesLink = hasPlan
    ? inNetworkPharmacyFinderLink
    : Routes.pharmacy;
  return (
    <AlertMessageLink
      {...props}
      to={{
        pathname: alertMessageContext.isFromSummaryPage
          ? summaryPageAddPharmaciesLink
          : inNetworkPharmacyFinderLink,
        search: alertMessageContext?.search,
        state: alertMessageContext?.routerState,
      }}
      condition={AlertMessageConditions.NO_SAVED_PHARMACIES}
    >
      {children}
    </AlertMessageLink>
  );
};
