import { Button } from "@cmsgov/ds-medicare-gov";
import { Dispatch, SetStateAction, useCallback } from "react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
  AnalyticsActionType,
  Ga4Event,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../../app/contexts/Analytics/types";
import { useIsCsrSession } from "../../helpers/csrHelpers";
import { useLogout } from "../../helpers/sessionHelpers";
import { msToMinutes } from "../../helpers/timeHelpers";
import { useTranslate } from "../../helpers/intlHooks";
import { useAppContext } from "../../helpers/context-hooks/useAppContext";
import { MandatoryDialog } from "../MandatoryDialog";
import { extendSession } from "./helpers";
import messages from "../../translations/en-US.json";
import { IIdleTimer } from "react-idle-timer";

export interface ForceLogoutModalProps {
  forceLogoutTimeoutMs: number;
  initialSessionTimeoutMsRef: React.MutableRefObject<number>;
  message: IIdleTimer["message"];
  pauseTimer: IIdleTimer["pause"];
  setShowForceLogoutModal: Dispatch<SetStateAction<boolean>>;
  updateRemainingTime: (timeUntilSessionExpiresMs: number) => void;
}

export const ForceLogoutModal = ({
  forceLogoutTimeoutMs,
  initialSessionTimeoutMsRef,
  message,
  pauseTimer,
  setShowForceLogoutModal,
  updateRemainingTime,
}: ForceLogoutModalProps) => {
  const {
    dispatch,
    state: { beneficiary },
  } = useAppContext();
  const t = useTranslate();
  const isCsr = useIsCsrSession();
  const logout = useLogout();

  /**
   * Pass `true` for `userInitiated` if this handler was called from a button click
   * event
   */
  const onExitHandler = useCallback(
    async (userInitiated = false) => {
      // Because this is a custom implementation (`MandatoryDialog`), we
      // need to call `extendSession` in the Continue button's `onClick` and
      // in the Dialog's `onExit` prop callbacks
      const { timeUntilSessionExpiresMs } = await extendSession({
        beneficiary,
        dispatch,
        initialSessionTimeoutMsRef,
        isCsr,
        userInitiated,
      });
      updateRemainingTime(timeUntilSessionExpiresMs);
    },
    [
      beneficiary,
      dispatch,
      initialSessionTimeoutMsRef,
      isCsr,
      updateRemainingTime,
    ]
  );

  return (
    <MandatoryDialog
      heading={t("user_activity.title")}
      onExit={() => onExitHandler()}
      actions={[
        <Button
          key="continue"
          onClick={() => onExitHandler(true)}
          variation="solid"
        >
          {t("user_activity.continue_session")}
        </Button>,
        <Button
          key="logout"
          /** This is an active logout, so doesn't use the `endSession` helper */
          onClick={() => {
            pauseTimer();
            setShowForceLogoutModal(false);
            message({ timeUntilSessionExpiresMs: 0 });
            logout({
              sessionHasExpired: false,
              onLogout: () => {
                dispatch({
                  type: AnalyticsActionType.SEND_GA4_EVENT,
                  settings: {
                    event_name: Ga4Event.USER_END_IDLE_SESSION,
                  },
                });
                dispatch({
                  type: AnalyticsActionType.SEND_TEALIUM_EVENT,
                  settings: {
                    event_label: "mct_plan_finder_user_end_idle_session",
                    event_action: "idle session - user ended session",
                    other_props: {
                      beneficiary_key: beneficiary?.meta_data.beneficiary_key,
                      csr_id: beneficiary?.csr_id,
                    },
                  },
                });
                dispatch({
                  type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                  settings: {
                    button: {
                      buttonStyle: AnalyticsButtonStyle.TRANSPARENT,
                      buttonType: AnalyticsButtonType.BUTTON,
                      text: messages["user_activity.logout"],
                    },
                  },
                });
              },
            });
          }}
          variation="ghost"
        >
          {t("user_activity.logout")}
        </Button>,
      ]}
    >
      <FormattedMessage
        id="idle_timeout.inactive"
        values={{
          minutes: Math.ceil(msToMinutes(forceLogoutTimeoutMs)),
        }}
      />
    </MandatoryDialog>
  );
};
