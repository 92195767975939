import { createContext, useEffect, useReducer } from "react";
import * as React from "react";
import { Action, ActionType, AppState } from "../../../@types";
import { useAppContext } from "../../../helpers/context-hooks/useAppContext";
import { defaultAlertMessageState } from "./constants";
import { flattenMessagingState, setMessageStatuses } from "./helpers";
import { FlattenedMessageState, MessagesState } from "./types";

type Dispatch = (action: Action) => void;
type AlertProviderProps = {
  children: React.ReactNode;
};

const AlertMessageContext = createContext<
  | {
      state: FlattenedMessageState;
      dispatch: Dispatch;
    }
  | undefined
>(undefined);

function alertMessageReducer(
  state: MessagesState,
  action: Action
): MessagesState {
  const { payload } = action;
  switch (action.type) {
    case ActionType.LOWEST_COST_PHARMACY_PARAMETERS_UPDATED: {
      return setMessageStatuses({
        messageState: state,
        appState: payload as AppState,
      });
    }
    default: {
      return state;
    }
  }
}

function AlertMessageProvider({ children }: AlertProviderProps) {
  const { state: appState } = useAppContext();
  const [state, dispatch] = useReducer(alertMessageReducer, {
    messages: defaultAlertMessageState,
  });

  useEffect(() => {
    dispatch({
      type: ActionType.LOWEST_COST_PHARMACY_PARAMETERS_UPDATED,
      payload: appState,
    });
  }, [
    appState.pharmacies,
    appState.pharmacyType,
    appState.beneficiary,
    appState.prescriptions,
    appState.mailOrderNetworkStatus,
    appState.routeParams,
  ]);

  const value = {
    state: {
      messages: flattenMessagingState(state.messages),
    },
    dispatch,
  };

  return (
    <AlertMessageContext.Provider value={value}>
      {children}
    </AlertMessageContext.Provider>
  );
}

function useAlertMessaging() {
  const context = React.useContext(AlertMessageContext);
  if (context === undefined) {
    throw new Error(
      "useAlertMessaging must be used within a AlertMessageProvider"
    );
  }
  return context;
}

export { AlertMessageProvider, useAlertMessaging };
