import { useEffect, useState } from "react";

/**
 * Tracks whether a user has set `prefers-reduced-motion` in their OS or browser
 * Adds a media-query listener to keep track of changes to that setting and updates
 * the returned value, accordingly
 */
export const usePrefersReducedMotion = () => {
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(
    window?.matchMedia("screen and (prefers-reduced-motion: reduce)").matches
  );

  useEffect(() => {
    const mql = window?.matchMedia(
      "screen and (prefers-reduced-motion: reduce)"
    );
    const mqlListener = (e: MediaQueryListEvent) => {
      setPrefersReducedMotion(e.matches);
    };
    mql &&
      mql.addEventListener &&
      mql.addEventListener("change", mqlListener, false);
    return () => {
      mql &&
        mql.removeEventListener &&
        mql.removeEventListener("change", mqlListener);
    };
  }, []);

  return prefersReducedMotion;
};
