import { useState, useEffect, ChangeEvent, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import routes from "../app/routes";
import { Button } from "@cmsgov/ds-medicare-gov";
import { parseSearchParams } from "../helpers/objectUtilities";
import { useTranslate } from "../helpers/intlHooks";
import { convertLocationToUriStripManagedParams } from "../helpers/urlHelpers";
import { getPlanTypeForCoverageType } from "../helpers/planHelpers";
import { useAppContext } from "../helpers/context-hooks/useAppContext";
import {
  ActionType,
  PlanType,
  County,
  CoverageType,
  FCWithChildren,
} from "../@types";
import { CountyPicker } from "./CountyPicker";
import URI from "urijs";
import { getCounty } from "../api";
import { stringIsValidPlanType } from "../helpers/planTypes";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../app/contexts/Analytics/types";
import { MandatoryDialog } from "./MandatoryDialog";
import { PlanQuestions } from "./PlanQuestions";

enum MissingValue {
  PLAN_TYPE = "plan_type",
  FIPS = "fips",
  ZIP = "zip",
}

export const StateValidator: FCWithChildren = ({ children }) => {
  const t = useTranslate();
  const location = useLocation();
  const history = useHistory();

  // * Context
  const { state, dispatch } = useAppContext();

  // * State
  const [showModal, setShowModal] = useState(false);
  const [coverageSelection, setCoverageSelection] = useState<CoverageType>(
    CoverageType.selectionNotMade
  );
  const [newLocation, setNewLocation] = useState<{
    county: County;
    zipcode: string;
  } | null>(null);
  const [missingValues, setMissingValues] = useState<MissingValue[]>([]);

  // * Constants
  const missingValue: MissingValue | undefined = missingValues[0];

  // * Memos
  const title = useMemo(() => {
    if (
      missingValue === MissingValue.FIPS ||
      missingValue === MissingValue.ZIP
    ) {
      return t("state_validator.enter_zip");
    } else if (missingValue === MissingValue.PLAN_TYPE) {
      return (
        <FormattedMessage
          id="state_validator.what_type_of_coverage_for_year"
          values={{ year: state.year }}
        />
      );
    }
    return "";
  }, [missingValue, state.year, t]);

  // * Functions
  const handleLocationSelection = (county?: County, zipcode?: string): void => {
    if (county && county.fips && zipcode) {
      setNewLocation({ county, zipcode });
      dispatch({
        type: AnalyticsActionType.SEND_TEALIUM_EVENT,
        settings: {
          event_action: "Missing Routing Info Modal - Zip Code",
          event_label: "Enter Zip Code",
        },
      });
    } else {
      setNewLocation(null);
    }
  };

  const updatePlanType = (): void => {
    dispatch({
      type: ActionType.UPDATE_PLAN_TYPE,
      payload: getPlanTypeForCoverageType(coverageSelection),
    });

    dispatch({
      type: AnalyticsActionType.SEND_TEALIUM_EVENT,
      settings: {
        event_action: "Missing Routing Info Modal - Type of Coverage",
        event_label: "Continue",
      },
    });
  };

  const updateLocation = (): void => {
    if (newLocation) {
      const { county, zipcode } = newLocation;

      dispatch({ type: ActionType.UPDATE_COUNTY, payload: county });
      dispatch({ type: ActionType.UPDATE_FIPS, payload: county.fips });
      dispatch({ type: ActionType.UPDATE_ZIPCODE, payload: zipcode });

      setNewLocation(null);

      dispatch({
        type: AnalyticsActionType.SEND_TEALIUM_EVENT,
        settings: {
          event_action: "Missing Routing Info Modal - Zip Code",
          event_label: "Continue",
        },
      });
    }
  };

  const updateUrl = (): void => {
    const newUrl = convertLocationToUriStripManagedParams(location);

    if (state.planType) {
      /**
       * Use the `setSearch` method to replace the value in the URI, in the case
       * that the search param is already set (sometimes without a value)
       */
      newUrl.setSearch("plan_type", state.planType);
    }

    if (state.fips) {
      newUrl.setSearch("fips", state.fips);
    }

    if (state.zipcode) {
      newUrl.setSearch("zip", state.zipcode);
    }

    history.replace(newUrl.valueOf());
  };

  const validatePlanType = (
    missingValues: MissingValue[],
    plan_type: string
  ): MissingValue[] => {
    let planType = "";

    if (plan_type) {
      planType = plan_type.toUpperCase();
    }

    if (stringIsValidPlanType(planType)) {
      planType = planType as PlanType;
    } else {
      planType = "";
    }

    if (planType && planType !== state.planType) {
      dispatch({
        type: ActionType.UPDATE_PLAN_TYPE,
        payload: planType,
      });

      missingValues = missingValues.filter(v => v !== MissingValue.PLAN_TYPE);
    } else if (!planType && state.planType) {
      updateUrl();

      missingValues = missingValues.filter(v => v !== MissingValue.PLAN_TYPE);
    } else if (
      !planType &&
      !state.planType &&
      !missingValues.includes(MissingValue.PLAN_TYPE)
    ) {
      missingValues.push(MissingValue.PLAN_TYPE);
    }

    return missingValues;
  };

  const validateLocation = (
    missingValues: MissingValue[],
    fips: string,
    zip: string
  ): MissingValue[] => {
    if (!state.fips && fips) {
      dispatch({
        type: ActionType.UPDATE_FIPS,
        payload: fips,
      });

      getCounty(fips).then(county =>
        dispatch({ type: ActionType.UPDATE_COUNTY, payload: county })
      );

      missingValues = missingValues.filter(v => v !== MissingValue.FIPS);
    } else if (!fips && state.fips) {
      updateUrl();

      missingValues = missingValues.filter(v => v !== MissingValue.FIPS);
    } else if (
      !fips &&
      !state.fips &&
      !missingValues.includes(MissingValue.FIPS)
    ) {
      missingValues.push(MissingValue.FIPS);
    }

    if (!state.zipcode && zip) {
      dispatch({
        type: ActionType.UPDATE_ZIPCODE,
        payload: zip,
      });

      missingValues = missingValues.filter(v => v !== MissingValue.ZIP);
    } else if (!zip && state.zipcode) {
      updateUrl();

      missingValues = missingValues.filter(v => v !== MissingValue.ZIP);
    } else if (
      !zip &&
      !state.zipcode &&
      !missingValues.includes(MissingValue.ZIP)
    ) {
      missingValues.push(MissingValue.ZIP);
    }

    return missingValues;
  };

  const validateState = (): void => {
    const url = new URI(location.search);
    const { plan_type, fips, zip } = parseSearchParams(url.search(true)) as {
      plan_type: string;
      fips: string;
      zip: string;
    };

    let values = [...missingValues];

    if (
      location.pathname === routes.pharmacy ||
      location.pathname.includes(routes.inNetworkPharmacy.replace(":id", ""))
    ) {
      values = validateLocation(values, fips, zip);
    } else if (location.pathname === routes.searchResults) {
      values = validatePlanType(values, plan_type);
      values = validateLocation(values, fips, zip);
    } else if (location.pathname === routes.sanctionedPlans) {
      values = validatePlanType(values, plan_type);
      values = validateLocation(values, fips, zip);
    } else if (location.pathname === routes.medigap.plans) {
      values = validateLocation(values, fips, zip);
    } else if (
      location.pathname.includes(
        routes.medigap.planDetails.replace(":medigapPlanType", "")
      ) ||
      location.pathname.includes(
        routes.medigap.policies.replace(":medigapPlanType", "")
      )
    ) {
      values = validateLocation(values, fips, zip);
    } else if (location.pathname === routes.coverageWizard.info) {
      values = validateLocation(values, fips, zip);
    }

    setMissingValues(values);
  };

  // * Effects

  useEffect(() => {
    if (missingValues.length) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [missingValues.length]);

  useEffect(validateState, [state, location.pathname, location.search]);

  return (
    <>
      {showModal && missingValue && (
        <MandatoryDialog
          data-cy="e2e-state-validator"
          onExit={(): void => setShowModal(false)}
          heading={title}
          closeButtonText=""
        >
          {[MissingValue.ZIP, MissingValue.FIPS].includes(missingValue) && (
            <>
              <p className="ChangeLocationModal__message">
                {t("state_validator.zip_tailor")}
              </p>
              <CountyPicker
                label={t("pharmacy_selection.change_location.label")}
                onCountySelect={handleLocationSelection}
                className="ds-u-margin-bottom--2"
                initialZip={state.zipcode}
              />
              <Button
                className="ds-u-margin-right--2 e2e-state-validator-continue"
                key="primary"
                variation="solid"
                onClick={() => {
                  updateLocation();
                  dispatch({
                    type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                    settings: {
                      button: {
                        buttonStyle: AnalyticsButtonStyle.PRIMARY,
                        buttonType: AnalyticsButtonType.BUTTON,
                        text: t("state_validator.continue"),
                      },
                    },
                  });
                }}
                disabled={!newLocation}
              >
                {t("state_validator.continue")}
              </Button>
            </>
          )}

          {missingValue === MissingValue.PLAN_TYPE && (
            <>
              <PlanQuestions
                handleChoice={(e: ChangeEvent<HTMLInputElement>): void => {
                  setCoverageSelection(e.target.value as CoverageType);
                  dispatch({
                    type: AnalyticsActionType.SEND_TEALIUM_EVENT,
                    settings: {
                      event_action:
                        "Missing Routing Info Modal - Type of Coverage",
                      event_label: e.target.value,
                    },
                  });
                }}
                coverageSelection={coverageSelection}
                displayPlanDescription={true}
              />
              <Button
                className="ds-u-margin-right--2 ds-u-margin-top--2"
                key="primary"
                variation="solid"
                onClick={() => {
                  updatePlanType();
                  dispatch({
                    type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                    settings: {
                      button: {
                        buttonStyle: AnalyticsButtonStyle.PRIMARY,
                        buttonType: AnalyticsButtonType.BUTTON,
                        text: t("state_validator.continue"),
                      },
                    },
                  });
                }}
                disabled={coverageSelection === CoverageType.selectionNotMade}
              >
                {t("state_validator.continue")}
              </Button>
            </>
          )}
        </MandatoryDialog>
      )}
      {children}
    </>
  );
};
