import { PropsWithChildren, useEffect, useReducer } from "react";
import { AppContext } from "./AppStore";
import { storageKey } from "../../helpers/storeHelpers";
import { useHistory } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import isEqual from "lodash.isequal";
import { reducer } from "./reducer";
import { initialAppState } from "./appStoreHelpers";
import { useSearchParams } from "../../helpers/routing-hooks/useSearchParams";
import { AppState, ActionType } from "../../@types";

export const AppStoreProvider = ({
  initialState,
  children,
}: PropsWithChildren<{
  initialState?: AppState;
}>) => {
  // * Hooks
  const history = useHistory();
  const flags = useFlags();
  const { selectedLanguage, selectedYear } = useSearchParams();

  // * Reducer
  const [state, dispatch] = useReducer(
    reducer,
    initialState || {
      ...initialAppState,
      tealiumLoaded: false,
      language: selectedLanguage || initialAppState.language,
      year: selectedYear ? String(selectedYear) : initialAppState.year,
    }
  );

  // * Effects

  useEffect(() => {
    // Every time the state changes, store it in sessionStorage
    window.sessionStorage.setItem(storageKey, JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    // Need deep equality check to handle memory leak caused by jest-launchdarkly-mock recreating flags object every test run case run
    if (!isEqual(state.cachedFlags, flags)) {
      dispatch({ type: ActionType.UPDATE_LD_FLAGS, payload: flags });
    }
  }, [flags]);

  // For the purposes of testing global session management, we need to be able to
  // set app state within integration tests

  useEffect(() => {
    if (window.playwright) {
      window.store = {
        state,
        dispatch,
      };
      window.router = { history };
    }
  }, [history, state]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
