import { FunctionComponent, useEffect } from "react";
import { Dialog, DialogProps } from "@cmsgov/ds-medicare-gov";

export const MandatoryDialog: FunctionComponent<DialogProps> = ({
  children,
  ...otherProps
}) => {
  useEffect(() => {
    const handleEscapeButton = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
      }
    };
    document.addEventListener("keydown", handleEscapeButton, { capture: true });
    return () => document.removeEventListener("keydown", handleEscapeButton);
  }, []);

  return <Dialog {...otherProps}>{children}</Dialog>;
};
