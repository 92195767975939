import {
  AppState,
  DrugCosts,
  PlanType,
  UseTranslateType,
} from "../../../@types";
import { isOverThirtyDaysSinceLogin } from "../../../helpers/beneficiaryInfoHelpers";
import { isValidPlanLongId } from "../../../helpers/planHelpers";
import { hasMailOrderPharmacy } from "../../store/appStoreHelpers";
import { alertMessageHierarchy } from "./constants";
import {
  AlertMessageConditions,
  AlertMessageHierarchyKeys,
  AlertMessageType,
  FormattedAlertMessage,
  MessagesState,
  AlertMessageContext,
} from "./types";
import {
  NoSavedPharmaciesLink,
  NoSavedDrugsLink,
  OneSavedPharmacyStaleAccountLink,
  OneSavedPharmacyLink,
} from "./components";

export const planComparePlanAlertMessageTestId =
  "alert-message-plan-compare-plan";

export function flattenMessagingState(
  state: Record<AlertMessageConditions, AlertMessageType>
): AlertMessageType[] {
  const result: AlertMessageType[] = [];
  for (const [key, value] of Object.entries(state)) {
    result[key] = value;
  }
  return result;
}

const formatMessage = ({
  selectedMessage,
  t,
  alertMessageContext = {},
}: {
  selectedMessage: AlertMessageType;
  t: UseTranslateType;
  alertMessageContext: AlertMessageContext;
}): FormattedAlertMessage => {
  return {
    ...selectedMessage,
    title: selectedMessage.title
      ? selectedMessage.title(t, alertMessageContext)
      : undefined,
    action: selectedMessage.action
      ? selectedMessage.action(t, alertMessageContext)
      : undefined,
    body: selectedMessage.body(t, alertMessageContext),
    icon: selectedMessage.icon,
  };
};

export function getMessageToShow({
  availableMessages,
  messageOrderHierarchy,
  t,
  alertMessageContext = {},
}: {
  availableMessages: AlertMessageType[];
  messageOrderHierarchy: Partial<AlertMessageConditions>[];
  t: UseTranslateType;
  alertMessageContext: AlertMessageContext;
}): FormattedAlertMessage | undefined {
  const { planType, planId, planHasPreferredPharmacies } = alertMessageContext;
  let result: FormattedAlertMessage | undefined = undefined;
  for (let i = 0; i < messageOrderHierarchy.length; i++) {
    const messageKey = messageOrderHierarchy[i];
    const selectedMessage = availableMessages[messageKey];
    if (selectedMessage.active) {
      if (
        selectedMessage?.key ===
          AlertMessageConditions.NO_PREFERRED_SAVED_PHARMACIES &&
        planId &&
        !planHasPreferredPharmacies
      ) {
        // suppress preferred pharmacy alert if the plan does not offer any preferred pharmacies
        continue;
      } else if (
        planType === PlanType.MA &&
        selectedMessage?.key !== AlertMessageConditions.NO_SAVED_DRUGS
      ) {
        continue;
      } else {
        result = formatMessage({ selectedMessage, t, alertMessageContext });
        break;
      }
    }
  }
  return result;
}

/**
 * Overrides default LCP Alert Messaging by examining DrugCosts to derive Network
 * Status messages. It is used for Plan Compare (multiple plans on a page)
 *
 * - If no pharmacy is in-network, returns a message prompting to find an in-network
 * pharmacy
 * - If any pharmacy is in-network but there are no preferred pharmacies, returns
 * a message prompting to find a preferred pharmacy
 * - If any other type of message of higher priority is set to `active`, returns `undefined`
 *
 * This helper does not rely on the `active` property of network-status messages,
 * as it's passing in DrugCosts instead of relying on values set in AppState,
 * based on a plan also in AppState.
 *
 * Other types of messages with higher priority and `active` status will take
 * precedence over network-status messages.
 */
export function getNetworkStatusMessageForPlan({
  availableMessages,
  costs,
  hasMailOrderPharmacies,
  t,
  alertMessageContext,
}: {
  availableMessages: AlertMessageType[];
  costs: DrugCosts[];
  hasMailOrderPharmacies: boolean;
  t: UseTranslateType;
  alertMessageContext: AlertMessageContext;
}): FormattedAlertMessage | undefined {
  const { planType, planHasPreferredPharmacies } = alertMessageContext;
  if (costs.length === 0 || planType === PlanType.MA) {
    return;
  }
  let result: FormattedAlertMessage | undefined = undefined;
  let costsToUse = [...costs];
  if (!hasMailOrderPharmacies) {
    costsToUse = [...costs].filter(cost => !cost.mail_order);
  }
  // Concat all messages for Plan Compare, because general alert messages set
  // to `active` will likely get priority over network status messages, if they
  // have a higher priority in the hierarchy (as of the time this was written,
  // they all do)
  const messageOrderHierarchy = alertMessageHierarchy[
    AlertMessageHierarchyKeys.PLAN_COMPARISON_NETWORK_STATUS
  ].concat(
    alertMessageHierarchy[AlertMessageHierarchyKeys.PLAN_COMPARISON_TABLE]
  );
  const networkStatuses = costsToUse.reduce(
    (acc, curr) => {
      return {
        in_network: acc.in_network ? acc.in_network : curr.in_network,
        preferred: acc.preferred ? acc.preferred : curr.preferred,
      };
    },
    { in_network: false, preferred: false }
  );
  for (let i = 0; i < messageOrderHierarchy.length; i++) {
    const messageKey = messageOrderHierarchy[i];
    const isNotNetworkStatusMessage =
      messageKey !== AlertMessageConditions.NO_IN_NETWORK_SAVED_PHARMACIES &&
      messageKey !== AlertMessageConditions.NO_PREFERRED_SAVED_PHARMACIES;
    const hasPriorityOverNetworkMessages = // lower numbers are higher priority
      messageKey < AlertMessageConditions.NO_IN_NETWORK_SAVED_PHARMACIES &&
      messageKey < AlertMessageConditions.NO_PREFERRED_SAVED_PHARMACIES;
    const selectedMessage = availableMessages[messageKey];
    // If one of the messages unrelated to network status is active, end the loop
    // and return undefined
    if (
      isNotNetworkStatusMessage &&
      hasPriorityOverNetworkMessages &&
      selectedMessage.active
    ) {
      result = undefined;
      break;
    }
    // Manual logic for setting network-status messages based on values taken
    // from drug costs for a specific plan being compared
    if (
      (!networkStatuses.in_network &&
        messageKey === AlertMessageConditions.NO_IN_NETWORK_SAVED_PHARMACIES) ||
      (networkStatuses.in_network &&
        !networkStatuses.preferred &&
        planHasPreferredPharmacies !== false &&
        messageKey === AlertMessageConditions.NO_PREFERRED_SAVED_PHARMACIES)
    ) {
      result = formatMessage({ selectedMessage, t, alertMessageContext });
      continue;
    }
  }
  return result;
}

export function setMessageStatuses({
  messageState,
  appState,
}: {
  messageState: MessagesState;
  appState: AppState;
}): MessagesState {
  const { messages } = messageState;
  const result = messages;
  const beneHasMailOrderPharmacy = hasMailOrderPharmacy(appState);
  const mailOrderCount = beneHasMailOrderPharmacy ? 1 : 0;
  const {
    mailOrderNetworkStatus = { preferred: false, inNetwork: false },
    prescriptions,
    pharmacies,
    beneficiary,
    routeParams,
  } = appState;
  const totalPharmacyCount = pharmacies.length + mailOrderCount;
  const drugsCount = prescriptions.length;
  const isAccountStale = beneficiary
    ? isOverThirtyDaysSinceLogin(beneficiary)
    : false;
  const hasInNetworkPharmacy =
    pharmacies.some(pharmacy => pharmacy.in_network) ||
    (beneHasMailOrderPharmacy && mailOrderNetworkStatus.inNetwork);
  const hasPreferredPharmacy =
    pharmacies.some(pharmacy => pharmacy.preferred) ||
    (beneHasMailOrderPharmacy && mailOrderNetworkStatus.preferred);

  // Conditions in which network pharmacy status alerts should be suppressed
  let suppressPharmacyNetworkStatusMessages = false;
  const hasRoutePlanId = isValidPlanLongId(routeParams?.id || "");
  if (beneficiary && !beneficiary.coverage_current[0]) {
    suppressPharmacyNetworkStatusMessages = !hasRoutePlanId;
  }

  for (const [key, value] of Object.entries(messages)) {
    switch (Number(key)) {
      case AlertMessageConditions.NO_SAVED_PHARMACIES:
        result[AlertMessageConditions.NO_SAVED_PHARMACIES] = {
          ...value,
          active: totalPharmacyCount < 1,
          // action is overwritten here to provide state context to link
          action: (t, alertMessageContext) => {
            return (
              <NoSavedPharmaciesLink
                alertMessageContext={alertMessageContext}
                className="ds-c-button ds-c-button--solid ds-c-button--alternate"
                treatAsButton
              >
                {t("lcp.messages.add_pharmacies.button")}
              </NoSavedPharmaciesLink>
            );
          },
        };
        break;
      case AlertMessageConditions.NO_SAVED_DRUGS:
        result[AlertMessageConditions.NO_SAVED_DRUGS] = {
          ...value,
          active: drugsCount < 1,
          // action is overwritten here to provide state context to link
          action: (t, alertMessageContext) => (
            <NoSavedDrugsLink
              alertMessageContext={alertMessageContext}
              className="ds-c-button ds-c-button--solid ds-c-button--alternate"
              treatAsButton
            >
              {t("lcp.messages.add_drugs.button")}
            </NoSavedDrugsLink>
          ),
        };
        break;
      case AlertMessageConditions.ONE_SAVED_PHARMACY_STALE_ACCOUNT:
        result[AlertMessageConditions.ONE_SAVED_PHARMACY_STALE_ACCOUNT] = {
          ...value,
          active: totalPharmacyCount === 1 && isAccountStale,
          action: (t, alertMessageContext) => (
            <OneSavedPharmacyStaleAccountLink
              alertMessageContext={alertMessageContext}
              className="ds-c-button ds-c-button--solid ds-c-button--alternate"
              treatAsButton
            >
              {t("lcp.messages.add_more_pharmacies_stale.button")}
            </OneSavedPharmacyStaleAccountLink>
          ),
        };
        break;
      case AlertMessageConditions.ONE_SAVED_PHARMACY:
        result[AlertMessageConditions.ONE_SAVED_PHARMACY] = {
          ...value,
          active: totalPharmacyCount === 1,
          // action is overwritten here to provide state context to link
          action: (t, alertMessageContext) => (
            <OneSavedPharmacyLink
              className="ds-c-button ds-c-button--solid ds-c-button--alternate"
              alertMessageContext={alertMessageContext}
              treatAsButton
            >
              {t("lcp.messages.add_more_pharmacies_standard.button")}
            </OneSavedPharmacyLink>
          ),
        };
        break;
      case AlertMessageConditions.STALE_ACCOUNT_PHARMACIES:
        result[AlertMessageConditions.STALE_ACCOUNT_PHARMACIES] = {
          ...value,
          active: isAccountStale,
        };
        break;
      case AlertMessageConditions.STALE_ACCOUNT_DRUGS:
        result[AlertMessageConditions.STALE_ACCOUNT_DRUGS] = {
          ...value,
          active: isAccountStale,
        };
        break;
      case AlertMessageConditions.NO_IN_NETWORK_SAVED_PHARMACIES:
        result[AlertMessageConditions.NO_IN_NETWORK_SAVED_PHARMACIES] = {
          ...value,
          active:
            !hasInNetworkPharmacy && !suppressPharmacyNetworkStatusMessages,
        };
        break;
      case AlertMessageConditions.NO_PREFERRED_SAVED_PHARMACIES:
        result[AlertMessageConditions.NO_PREFERRED_SAVED_PHARMACIES] = {
          ...value,
          active:
            !hasPreferredPharmacy && !suppressPharmacyNetworkStatusMessages,
        };
        break;
    }
  }
  return { messages };
}
