import { useCallback, useEffect } from "react";
import { toast, ToastItem, ToastOptions } from "react-toastify";
import { usePrefersReducedMotion } from "./mediaQueryHelpers";
import { OnChangeCallback } from "react-toastify/dist/core";

/**
 * Tracks whether a user has set `prefers-reduced-motion` in their OS or browser,
 * using the `usePrefersReducedMotion` hook.
 *
 * Depending on the user's settings either returns a toast duration value or `false`
 * and for users who prefer reduced motion, sets an `onChange` callback handler
 * that programmatically dismisses toasts after the passed in duration.
 *
 * If no duration is passed in, the toast should stay open, no matter the reduced
 * motion setting.
 *
 * @example
 * const autoClose = usePrefersReducedMotionToastHandler()
 * ...
 * toast("content", {autoClose});
 */
export const usePrefersReducedMotionToastHandler = (
  toastDuration?: number
): ToastOptions["autoClose"] => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const toastOnOpenHandler = useCallback<OnChangeCallback>(
    (toastItem: ToastItem) => {
      if (
        !prefersReducedMotion ||
        !(toastItem.status === "added") ||
        !toastDuration
      ) {
        return;
      }
      setTimeout(() => {
        toast.dismiss(toastItem.id);
      }, toastDuration);
    },
    [prefersReducedMotion, toastDuration]
  );
  useEffect(() => {
    toast.onChange(toastOnOpenHandler);
  }, [toastOnOpenHandler]);
  return prefersReducedMotion || !toastDuration ? false : toastDuration;
};
