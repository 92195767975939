import * as T from "./";
import * as TK from "./typeKeys";
import { isSomeEnum } from "../helpers/typeGuardHelpers";
import { Ga4Event } from "../app/contexts/Analytics/types";

/**
 * Utility Functions and Guards
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function areIn<Type>(props: (keyof Type)[], payload: any): boolean {
  return props.every(prop => prop in payload);
}

export function isBoolean(payload: unknown): payload is boolean {
  return typeof payload === "boolean";
}

export function isDate(payload: unknown): payload is Date {
  return payload instanceof Date;
}

export function isObject(payload: unknown): payload is Record<string, unknown> {
  return typeof payload === "object";
}

export function isString(payload: unknown): payload is string {
  return typeof payload === "string";
}

function isA<Type>(payload: unknown, props: (keyof Type)[]): payload is Type {
  return isObject(payload) && areIn<Type>(props, payload);
}

function enumIncludes(
  enumerator: Record<string, unknown>,
  payload: unknown
): boolean {
  return isString(payload) && Object.values(enumerator).includes(payload);
}

export function hasIndex(
  payload: unknown
): payload is { index: number; [x: string]: unknown } {
  return isObject(payload) && "index" in (payload as { index: number });
}

/**
 * @returns `true` if payload is an [] of items that pass the checker.
 * @note returns `true` for empty arrays.
 *  */
function hasTypes(
  payload: unknown,
  checker: (value: unknown) => boolean
): boolean {
  return (
    Array.isArray(payload) && (payload.length === 0 || payload.every(checker))
  );
}

/**
 * "is" guards - These guards check to make sure that the payload is *of* a certain type.
 */

export function isGlobalSessionInfo(
  payload: unknown
): payload is T.GlobalSessionInfo {
  return isA<T.GlobalSessionInfo>(payload, TK.GlobalSessionInfoKeys);
}

export function isAutocompleteDrug(
  payload: unknown
): payload is T.AutocompleteDrug {
  return isA<T.AutocompleteDrug>(payload, TK.AutocompleteDrugKeys);
}

export function isAutocompletePapDrug(
  payload: unknown
): payload is T.AutocompletePapDrugMctapi {
  return isA<T.AutocompletePapDrugMctapi>(payload, TK.AutocompletePapDrugKeys);
}

export function isBeneficiary(payload: unknown): payload is T.Beneficiary {
  return isA<T.Beneficiary>(payload, TK.BeneficiaryKeys);
}

export function isChatInit(payload: unknown): payload is T.ChatInit {
  return isA<T.ChatInit>(payload, TK.ChatInitKeys);
}

export function isCounty(payload: unknown): payload is T.County {
  return isA<T.County>(payload, TK.CountyKeys);
}

export function isCSR(payload: unknown): payload is T.CSR {
  return isA<T.CSR>(payload, TK.CSRKeys);
}

export function isDemographicInfo(
  payload: unknown
): payload is T.DemographicInfo {
  return isA<T.DemographicInfo>(payload, []) || payload === undefined;
}

export function isDrugCostInfo(payload: unknown): payload is T.DrugCostInfo {
  return isA<T.DrugCostInfo>(payload, TK.DrugCostInfoKeys);
}

export function isDrugCostsResponse(
  payload: unknown
): payload is T.DrugCostsResponse {
  return isA<T.DrugCostsResponse>(payload, TK.DrugCostsResponseKeys);
}

export function isDrugDosage(payload: unknown): payload is T.DrugDosage {
  return isA<T.DrugDosage>(payload, TK.DrugDosageKeys);
}

export function isDrugInfo(payload: unknown): payload is T.DrugInfo {
  return isA<T.DrugInfo>(payload, TK.DrugInfoKeys);
}

export function isEnrollResponse(
  payload: unknown
): payload is T.EnrollResponse {
  return isA<T.EnrollResponse>(payload, TK.EnrollResponseKeys);
}

export function isCwPremiumRangesResponse(
  payload: unknown
): payload is T.CwPremiumRanges {
  return isA<T.CwPremiumRanges>(payload, TK.CwPremiumRangesKeys);
}

export function isFullPlanId(payload: unknown): payload is T.FullPlanId {
  return isA<T.FullPlanId>(payload, TK.FullPlanIdKeys);
}

export function isLanguage(payload: unknown): payload is T.UserLanguage {
  return enumIncludes(T.UserLanguage, payload);
}

export function isLIS(payload: unknown): payload is T.LowIncomeSubsidyStatus {
  return enumIncludes(T.LowIncomeSubsidyStatus, payload);
}

export const isMedigapPlanPolicy = (
  payload: unknown
): payload is T.MedigapPlanPolicy =>
  isA<T.MedigapPlanPolicy>(payload, TK.MedigapPlanPolicyKeys);

export const isMedigapPlanPremiumRange = (
  payload: unknown
): payload is T.MedigapPlanPremiumRange =>
  isA<T.MedigapPlanPremiumRange>(payload, TK.MedigapPlanPremiumRangeKeys);

export function isOECStatus(payload: unknown): payload is T.OecStatus {
  return isA<T.OecStatus>(payload, TK.OecStatusKeys);
}

export function isPharmacy(payload: unknown): payload is T.Pharmacy {
  return isA<T.Pharmacy>(payload, TK.PharmacyKeys);
}

export function isMailOrderPharmacyStatus(
  payload: unknown
): payload is T.MailOrderNetworkStatus {
  return isA<T.MailOrderNetworkStatus>(payload, TK.MailOrderNetworkStatusKeys);
}

export function isPharmacyInfo(payload: unknown): payload is T.PharmacyInfo {
  return isA<T.PharmacyInfo>(payload, TK.PharmacyInfoKeys);
}

export const isPlanDataVersion = (
  payload: unknown
): payload is T.PlanDataVersion =>
  isA<T.PlanDataVersion>(payload, TK.PlanDataVersionKeys);

export function isPrescription(
  payload: unknown
): payload is T.PrescriptionDrug {
  return isA<T.PrescriptionDrug>(payload, TK.PrescriptionDrugKeys);
}

export function isPharmaciesRequest(
  payload: unknown
): payload is T.PharmaciesRequest {
  return isA<T.PharmaciesRequest>(payload, TK.PharmaciesRequestKeys);
}

export function isPharmaciesResponse(
  payload: unknown
): payload is T.PharmaciesResponse {
  return isA<T.PharmaciesResponse>(payload, TK.PharmaciesResponseKeys);
}

export function isLdFlagsResponse(
  payload: unknown
): payload is T.LdFlagsResponse {
  return isA<T.LdFlagsResponse>(payload, TK.LdFlagsResponseKeys);
}

export function isInNetworkPharmaciesRequest(
  payload: unknown
): payload is T.InNetworkPharmaciesRequest {
  return isA<T.InNetworkPharmaciesRequest>(
    payload,
    TK.InNetworkPharmaciesRequestKeys
  );
}

export function isInNetworkPharmaciesResponse(
  payload: unknown
): payload is T.InNetworkPharmaciesResponse {
  return isA<T.InNetworkPharmaciesResponse>(
    payload,
    TK.InNetworkPharmaciesResponseKeys
  );
}

export function isPharmacyType(payload: unknown): payload is T.PharmacyType {
  return enumIncludes(T.PharmacyType, payload);
}

/**
 * @deprecated - marked as such because this is not reliable.
 * remove tag when functional.
 * https://jira.cms.gov/browse/MCT-9129
 */
export function isPlan(payload: unknown): payload is T.Plan {
  return isA<T.Plan>(payload, TK.PlanKeys);
}

export function isPlanResults(payload: unknown): payload is T.PlanResults {
  return isA<T.PlanResults>(payload, TK.PlanResultsKeys);
}

export function isPlanType(payload: unknown): payload is T.PlanType {
  return enumIncludes(T.PlanType, payload);
}

/**
 * @deprecated - marked as such because this is not reliable.
 * remove tag when functional.
 * https://jira.cms.gov/browse/MCT-9129
 */
export function isSearchResultPlan(
  payload: unknown
): payload is T.SearchResultPlan {
  return isA<T.SearchResultPlan>(payload, TK.SearchResultPlanKeys);
}

export function isStarRating(payload: unknown): payload is T.StarRating {
  return isA<T.StarRating>(payload, TK.StarRatingKeys);
}

export function isPlanOrSRP(payload: unknown): payload is T.PlanOrSRP {
  return isA<T.PlanOrSRP>(payload, TK.BasePlanKeys);
}

/**
 * "has" guards - These guards check to make sure the payload contains one or more items *of* a certain type.
 */
export function hasAutocompleteDrugs(
  payload: unknown
): payload is T.AutocompleteDrug[] {
  return hasTypes(payload, isAutocompleteDrug);
}

export function hasAutocompletePapDrugs(
  payload: unknown
): payload is T.AutocompletePapDrugMctapi[] {
  return hasTypes(payload, isAutocompletePapDrug);
}

export function hasCounties(payload: unknown): payload is T.County[] {
  return hasTypes(payload, isCounty);
}

export function hasDrug(
  payload: unknown
): payload is { drug: T.PrescriptionDrug } {
  if (!payload) return false;

  const { drug } = payload as { drug: T.PrescriptionDrug };
  return isPrescription(drug);
}

export function hasDrugDosages(payload: unknown): payload is T.DrugDosage[] {
  return hasTypes(payload, isDrugDosage);
}

export function hasDrugInfo(payload: unknown): payload is T.DrugInfo[] {
  return hasTypes(payload, isDrugInfo);
}

export function hasFiveDigitString(payload: unknown): payload is string {
  return (
    isString(payload) &&
    payload.length === 5 &&
    payload.split("").every(char => !isNaN(Number(char)))
  );
}

export function hasInsuranceCarriers(payload: unknown): payload is string[] {
  return hasTypes(payload, isString);
}

export const hasMedigapPlanPolicies = (
  payload: unknown
): payload is T.MedigapPlanPolicy[] => hasTypes(payload, isMedigapPlanPolicy);

export const hasMedigapPlanPremiumRanges = (
  payload: unknown
): payload is T.MedigapPlanPremiumRange[] =>
  hasTypes(payload, isMedigapPlanPremiumRange);

export function hasOecStatus(payload: unknown): payload is T.OecStatus[] {
  return hasTypes(payload, isOECStatus);
}

export const hasPlanDataVersions = (
  payload: unknown
): payload is T.PlanDataVersion[] => hasTypes(payload, isPlanDataVersion);

export function hasPrescriptions(
  payload: unknown
): payload is T.PrescriptionDrug[] {
  return hasTypes(payload, isPrescription);
}

export function hasPharmacies(payload: unknown): payload is T.Pharmacy[] {
  return hasTypes(payload, isPharmacy);
}

export function hasPlans(payload: unknown): payload is T.Plan[] {
  return hasTypes(payload, isPlan);
}

export function hasPlanSNPTypes(payload: unknown): payload is T.PlanSNPType[] {
  return (
    Array.isArray(payload) &&
    payload.every(item => enumIncludes(T.PlanSNPType, item))
  );
}

export function hasSearchResultPlans(
  payload: unknown
): payload is T.SearchResultPlan[] {
  return hasTypes(payload, isSearchResultPlan);
}

export function hasSearchResultsFilters(
  payload: unknown
): payload is T.SearchResultsFilters {
  return isA<T.SearchResultsFilters>(payload, TK.SearchResultsFiltersKeys);
}

export function hasStarRatings(payload: unknown): payload is T.StarRating[] {
  return hasTypes(payload, isStarRating);
}

export function hasStrings(payload: unknown): payload is string[] {
  return hasTypes(payload, isString);
}

export function hasYear(payload: unknown): payload is string {
  return (
    isString(payload) &&
    payload.length === 4 &&
    payload.split("").every(char => !isNaN(Number(char)))
  );
}

export function isPacePlan(payload: unknown): payload is T.PacePlan {
  return isA<T.PacePlan>(payload, TK.PacePlanKeys);
}

export function hasPacePlans(payload: unknown): payload is T.PacePlan[] {
  return hasTypes(payload, isPacePlan);
}

export function isPapPlan(payload: unknown): payload is T.PapPlan {
  return isA<T.PapPlan>(payload, TK.PapPlanKeys);
}

export function hasPapPlans(payload: unknown): payload is T.PapPlan[] {
  return hasTypes(payload, isPapPlan);
}

export function isSpapPlan(payload: unknown): payload is T.SpapPlan {
  return isA<T.SpapPlan>(payload, TK.SpapPlanKeys);
}

export function hasSpapPlans(payload: unknown): payload is T.SpapPlan[] {
  return hasTypes(payload, isSpapPlan);
}

export const isRegisteredEvent = (payload: unknown): payload is Ga4Event =>
  isSomeEnum(Ga4Event)(payload);

export function isPlanPreferredPharmacyInfo(
  payload: unknown
): payload is T.PlanPreferredPharmacyInfo {
  return isA<T.PlanPreferredPharmacyInfo>(
    payload,
    TK.PlanPreferredPharmacyInfoKeys
  );
}
export function hasPlanPreferredPharmacyInfo(
  payload: unknown
): payload is T.PlanPreferredPharmacyInfo[] {
  return hasTypes(payload, isPlanPreferredPharmacyInfo);
}

export function isNewToMedicareAlertInfo(
  payload: unknown
): payload is T.NewToMedicareAlertInfo {
  return isA<T.NewToMedicareAlertInfo>(payload, TK.NewToMedicareAlertInfoKeys);
}

/** ----- Other Insurances / Medigap ----- */

export function isBeneInfoOtherInsuranceInfo(
  payload: unknown
): payload is T.BeneInfoOtherInsurance {
  return isA<T.BeneInfoOtherInsurance>(payload, TK.BeneInfoOtherInsuranceKeys);
}

export function hasBeneInfoOtherInsuranceInfo(
  payload: unknown
): payload is T.BeneInfoOtherInsurance[] {
  return hasTypes(payload, isBeneInfoOtherInsuranceInfo);
}

export function isMedigapCoverage(
  payload: unknown
): payload is T.MedigapCoverage {
  return isA<T.MedigapCoverage>(payload, TK.BeneInfoOtherInsuranceKeys);
}

export function hasMedigapCoverages(
  payload: unknown
): payload is T.MedigapCoverage[] {
  return hasTypes(payload, isMedigapCoverage);
}
