/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { FC, MouseEventHandler, useEffect, useRef, useState } from "react";
import { useIdleTimeoutTestHelper } from "./helpers";
import {
  IdleTimeoutActivityState,
  UseIdleTimeoutTestHelperParams,
} from "./types";
import { msToMinsAndSeconds } from "../../helpers/timeHelpers";
import { IIdleTimer } from "react-idle-timer";

interface LogInfoProps extends UseIdleTimeoutTestHelperParams {
  activityState: IdleTimeoutActivityState | undefined;
  isIdle: IIdleTimer["isIdle"];
  promptBeforeIdle: number;
}

/**
 * Renders some debugging info if `config.FORCE_LOGOUT_TIMEOUT_INFO` is `true`,
 * otherwise nothing
 */
export const LogInfo: FC<LogInfoProps> = ({
  activityState,
  forceLogoutTimeoutMs,
  getElapsedTime,
  getLastActiveTime,
  getRemainingTime,
  isIdle,
  isLoggedIn,
  logoutTimeoutStartTime,
  promptBeforeIdle,
  showForceLogoutModal,
}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const ref = useRef<HTMLDivElement | null>(null);
  /** For debugging purposes, only renders when configured in the build */
  const { elapsed, lastActive, remaining, timeToLogout } =
    useIdleTimeoutTestHelper({
      forceLogoutTimeoutMs,
      getElapsedTime,
      getLastActiveTime,
      getRemainingTime,
      isLoggedIn,
      logoutTimeoutStartTime,
      showForceLogoutModal,
    });
  // drag-and-drop: Update the current position if mouse is down
  const onMouseMove: MouseEventHandler = event => {
    if (event.buttons === 1) {
      setPosition({
        x: position.x + event.movementX,
        y: position.y + event.movementY,
      });
    }
  };
  // drag-and-drop: Monitor changes to position state and update DOM
  useEffect(() => {
    if (ref.current) {
      ref.current.style.transform = `translate(${position.x}px, ${position.y}px)`;
    }
  }, [position]);

  return (
    <div
      id="loginfo"
      onMouseMove={onMouseMove}
      onMouseOver={() => {
        if (ref.current) ref.current.style.cursor = "pointer";
      }}
      ref={ref}
      role="presentation"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: 300,
        padding: "20px",
        backgroundColor: "white",
        opacity: 0.9,
        border: "1px solid grey",
        borderRadius: "10px",
        zIndex: 9996,
      }}
    >
      <div
        style={{
          display: "grid",
          marginBottom: "10px",
        }}
      >
        <button
          style={{
            justifySelf: "end",
            padding: "5px",
          }}
          onClick={() => ref.current?.setAttribute("style", "display: none")}
        >
          X
        </button>
      </div>

      <strong>
        Note: Any changes to global session based on user actions in MCT or
        activity on MBP may not be reflected in times shown here. This will
        account for state changes appearing to be out of sync with reported
        times.
      </strong>
      <hr />
      <div>
        IdleTimeoutActivityState (calculated from SLS time): {activityState}
      </div>
      <div>isLoggedIn (timer should have started): {`${isLoggedIn}`}</div>
      <div>
        Remaining time in session, based on last SLS query:{" "}
        {`${msToMinsAndSeconds(remaining)}`}
      </div>
      {promptBeforeIdle && promptBeforeIdle >= 0 && (
        <div>
          Remaining time to prompt, based on last SLS query:{" "}
          {`${msToMinsAndSeconds(remaining - promptBeforeIdle)}`}
        </div>
      )}
      {timeToLogout && timeToLogout > 0 && (
        <div>
          Time to logout, based on last SLS query (prompt should be shown):{" "}
          {`${msToMinsAndSeconds(timeToLogout)}`}
        </div>
      )}
      <hr />
      <strong>
        Values from react-idle-timer, which may differ from other values,
        derived from calls to SLS
      </strong>
      <div>Last active time: {lastActive?.toLocaleTimeString()}</div>
      <div>Time since mounted: {`${msToMinsAndSeconds(elapsed)}`}</div>
      <div>
        isIdle from IdleTimer (may differ from activityState, the source of
        truth): {`${isIdle()}`}
      </div>
    </div>
  );
};
