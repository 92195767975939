import { FunctionComponent, useState, useEffect, ChangeEvent } from "react";
import * as React from "react";
import { TextField, Button, ChoiceList } from "@cmsgov/ds-medicare-gov";
import { County } from "../@types";
import { useTranslate } from "../helpers/intlHooks";
import * as api from "../api";
import { limitInputLength } from "../helpers/objectUtilities";
import { isValidZipcode } from "../helpers/zipcodeHelpers";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../app/contexts/Analytics/types";
import { useAppContext } from "../helpers/context-hooks/useAppContext";

export function countyToString(county: County, zip: string): string {
  return `${zip}, ${county.name}, ${county.state}`;
}

interface CountyPickerProps {
  label: string;
  onCountySelect: (county?: County, zipcode?: string) => void;
  onCountySearch?: (zip: string) => void;
  initialZip?: string;
  initialFips?: string;
  className?: string;
  zipError?: string;
  countyError?: string;
  buttonLabel?: string;
  buttonCallback?: () => void;
}

export const CountyPicker: FunctionComponent<CountyPickerProps> = ({
  label,
  onCountySelect,
  onCountySearch,
  initialZip = "",
  initialFips,
  className,
  zipError,
  countyError,
  buttonLabel,
  buttonCallback,
}) => {
  const t = useTranslate();
  const { dispatch } = useAppContext();
  const [fetching, setFetching] = useState(false);
  const [shouldAutoFill, setShouldAutoFill] = useState(Boolean(initialFips));
  const [zipErrorMessage, setZipErrorMessage] = useState<undefined | string>(
    undefined
  );
  const [countyErrorMessage, setCountyErrorMessage] = useState<
    string | undefined
  >(undefined);
  const [zipcode, setZipcode] = useState(initialZip);
  const [countyResults, setCountyResults] = useState<County[]>([]);
  const [autocompleteInput, setAutocompleteInput] =
    useState<HTMLInputElement | null>(null);

  useEffect(() => {
    setZipErrorMessage(zipError);
  }, [zipError]);

  useEffect(() => {
    setCountyErrorMessage(countyError);
  }, [countyError]);

  const searchForCounties = async (autofillZip?: string): Promise<void> => {
    if (!autocompleteInput) {
      return;
    }
    setZipErrorMessage(undefined);

    const input = autofillZip || autocompleteInput.value;

    if (autofillZip) {
      autocompleteInput.value = autofillZip;
    }

    if (!autofillZip && onCountySearch) {
      onCountySearch(input);
    }

    if (isValidZipcode(input)) {
      setZipcode(input);
      setFetching(true);
      setCountyResults([]);
      onCountySelect();
      try {
        // TODO: Replace this call with `useCounties` hook. https://jira.cms.gov/browse/MCT-9682
        const counties = await api.getCounties(input);
        setCountyResults(counties);

        if (autofillZip) {
          const countyMatch = counties.find(
            county => county.fips == initialFips
          );

          if (countyMatch) {
            onCountySelect(countyMatch, autofillZip);
          }
        }

        if (counties.length === 1) {
          onCountySelect(counties[0], input);
        }
      } catch (error) {
        setZipErrorMessage(t("error_message.fetching_county"));
      }
      setFetching(false);
    } else {
      setZipErrorMessage(t("county_picker.error_message"));
    }
  };

  const onCountySelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onCountySelect(JSON.parse(e.target.value), zipcode);
  };

  const onSubmit = (e: ChangeEvent<HTMLFormElement>): void => {
    e.preventDefault();

    searchForCounties();
    setShouldAutoFill(false);

    if (buttonCallback) {
      buttonCallback();
    }
  };

  useEffect(() => {
    if (initialZip && autocompleteInput) {
      searchForCounties(initialZip);
    }
  }, [initialZip, autocompleteInput]);

  return (
    <div className={`CountyPicker ${className ? className : ""}`}>
      <form
        className="e2e-select-county CountyPicker__form"
        onSubmit={onSubmit}
      >
        <TextField
          label={label}
          labelClassName="e2e-enter-zip CountyPicker__label"
          className="CountyPicker__input"
          data-cy="e2e-county-picker-zip-input"
          onChange={(): void => limitInputLength("countyPicker input", 5)}
          name="zip"
          inputRef={(ref: HTMLInputElement): void => {
            setAutocompleteInput(ref);
          }}
          errorMessage={zipErrorMessage}
          errorMessageClassName="ds-u-font-size--base"
        />

        <Button
          className="e2eCountyPickerSelectBtn CountyPicker__submit"
          data-cy="e2e-county-picker-select-button"
          type="submit"
          variation="solid"
          onClick={() => {
            dispatch({
              type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
              settings: {
                button: {
                  buttonStyle: AnalyticsButtonStyle.PRIMARY,
                  buttonType: AnalyticsButtonType.SUBMIT,
                  text: buttonLabel || t("county_picker.button_text"),
                },
              },
            });
          }}
        >
          {buttonLabel || t("county_picker.button_text")}
        </Button>
      </form>
      {fetching && <p>{t("app.loading")}...</p>}
      {!!countyResults.length && (
        <ChoiceList
          name="county"
          label={t("county_picker.select_your_county")}
          type="radio"
          errorMessage={countyErrorMessage}
          choices={countyResults.map(county => {
            return {
              label: countyToString(county, zipcode),
              value: JSON.stringify(county),
              id: county.fips,
              defaultChecked:
                countyResults.length === 1
                  ? true
                  : shouldAutoFill
                    ? initialFips === county.fips
                    : false,
            };
          })}
          onChange={onCountySelected}
        />
      )}
    </div>
  );
};
