import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FCWithChildren } from "../@types";

export const ScrollRestoration: FCWithChildren = ({ children }) => {
  const [previousLocation, setPreviousLocation] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (previousLocation !== location.pathname) {
      window.scrollTo(0, 0);
      setPreviousLocation(location.pathname);
    }
  }, [location, previousLocation]);

  return <>{children}</>;
};
