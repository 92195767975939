import {
  AppState,
  NewToMedicareStatus,
  PrescriptionDrug,
  UserRole,
  UserType,
} from "../../../@types";
import { Location } from "history";
import { hasGlobalSession } from "../../../helpers/loginHelpers";
import { getLanguageFromLocationOrState } from "../../../helpers/languageHelpers";
import { getYearPartInfo } from "../../../helpers/yearFlagHelpers";
import routes, { beneLandingAndCallbackRoutes } from "../../routes";
import { Ga4EventDimensionsConfig, ga4EventDimensionValues } from "./constants";
import {
  Ga4EventDimensions,
  Ga4EventDimension,
  Ga4PageViewDimensions,
  TealiumPageViewProps,
  TealiumEventProps,
  PageViewProps,
  Ga4Event,
  LinkType,
  AnalyticsKey,
} from "./types";
import { LDFlagSet } from "launchdarkly-js-client-sdk";
import { useLocation } from "react-router-dom";
import { ParentComponentType } from "./eventTypes";
import {
  getHashPathName,
  getIsBeneLandingOrCallbackRoute,
  getRouteName,
} from "../../../helpers/routeHelpers";
import { getEnvironment } from "../../../helpers/urlHelpers";
import { getIsCsrSession } from "../../../helpers/csrHelpers";
import {
  PARENT_COMPONENT_HEADING_ATTRIBUTE,
  PARENT_COMPONENT_TYPE_ATTRIBUTE,
} from "../../../components/AnalyticsListeners";
/**
 * Constructs the proper GA4 object depending on state, analytics settings passed in, and typing in Ga4EventDimensionsConfig
 * @param settings
 * @param state
 */
export function createGa4Event({
  settings,
  state,
}: {
  settings: Ga4EventDimensions;
  state: AppState;
}): Ga4EventDimensions {
  const { event_name } = settings;
  const {
    beneficiary,
    csr,
    cwCoverage,
    language,
    lis,
    planType,
    prescriptions,
    cachedFlags: flags,
  } = state;
  const { isOutsideOpenEnrollment } = getYearPartInfo(flags);

  const logged_in = hasGlobalSession(state);
  /** for analytics, this should be true for any time outside of OOE */
  const open_enrollment = !isOutsideOpenEnrollment;

  const isCsr = csr || getHashPathName() === routes.csrLanding;
  const isBene = beneficiary || getIsBeneLandingOrCallbackRoute();

  const dimensionValueMapping: Partial<Record<Ga4EventDimension, unknown>> = {
    [Ga4EventDimension.CONTENT_LANGUAGE]: language,
    [Ga4EventDimension.LOGGED_IN]: logged_in,
    [Ga4EventDimension.MCT_COVERAGE_WIZARD]: !!cwCoverage,
    [Ga4EventDimension.MCT_INSURANCE_PROVIDER]: settings.mct_insurance_provider,
    [Ga4EventDimension.MCT_INSURANCE_TYPE]: settings.mct_insurance_type,
    [Ga4EventDimension.MCT_LIS]: lis,
    [Ga4EventDimension.MCT_MEDIGAP]: !!settings.mct_medigap,
    [Ga4EventDimension.MCT_PHARMACY_TYPE]:
      settings.mct_pharmacy_type !== undefined
        ? settings.mct_pharmacy_type
        : state.pharmacyType,
    [Ga4EventDimension.MCT_PLAN_TYPE]: settings.mct_plan_type || planType,
    [Ga4EventDimension.MCT_VIEWED_DRUGS]: prescriptions.length > 0,
    [Ga4EventDimension.OPEN_ENROLLMENT]: open_enrollment,
    [Ga4EventDimension.ROLE]: (() => {
      let role = UserRole.ANON_BENEFICIARY;
      if (isCsr) {
        role = UserRole.CSR;
      } else if (isBene) {
        role = UserRole.AUTH_BENEFICIARY;
      }
      return role;
    })(),
    [Ga4EventDimension.SITE_ENVIRONMENT]: getEnvironment(),
    [Ga4EventDimension.SITE_DOMAIN]: window.location.hostname,
    [Ga4EventDimension.CSR_ID]: beneficiary?.csr_id,
    [Ga4EventDimension.BENEFICIARY_KEY]: beneficiary?.meta_data.beneficiary_key,
  };
  const eventDimensions =
    Ga4EventDimensionsConfig[event_name as Ga4Event] || [];

  const dimensions = { event_name };

  eventDimensions.forEach((k: AnalyticsKey) => {
    const mappedValue = dimensionValueMapping[k];
    const settingsValue = settings[k];
    if (mappedValue !== undefined) {
      dimensions[k] = mappedValue;
    } else if (settingsValue !== undefined) {
      if (typeof settingsValue === "object" && !Array.isArray(settingsValue)) {
        Object.entries(settingsValue).forEach(([key, value]) => {
          if (
            ga4EventDimensionValues.find(dimension => dimension === key) &&
            typeof value !== "undefined"
          ) {
            dimensions[key] = value;
          }
        });
      } else {
        dimensions[k] = settingsValue;
      }
    }
  });
  return dimensions;
}

/**
 * Constructs and sends the proper GA4 object depending on state, analytics settings passed in, and typing in Ga4EventDimensionsConfig
 * @param settings
 * @param state
 */
export const sendGa4Event = ({
  settings,
  state,
}: {
  settings: Ga4EventDimensions;
  state: AppState;
}): void => {
  if (!window.utag?.link) {
    return;
  }
  const dimensions = createGa4Event({ settings, state });
  window.utag.link(dimensions as unknown as Record<string, unknown>);
};

/**
 * Constructs the proper UA object
 */
export const createTealiumEvent = ({
  event_action,
  event_label,
  other_props = {},
  event_category = "MCT",
}: TealiumEventProps) => ({
  event_category,
  event_action,
  event_label,
  event_noninteraction: 0,
  ...other_props,
});

/**
 * Constructs and sends the proper UA object
 */
export function sendTealiumEvent({
  event_action,
  event_label,
  other_props = {},
  event_category = "MCT",
}: TealiumEventProps): void {
  const linkDimensions = createTealiumEvent({
    event_category,
    event_action,
    event_label,
    other_props,
  });
  window.utag?.link(linkDimensions);
}

/**
 * Used on CSR Landing page only, otherwise use sendTealiumEvent
 */
export function sendTealiumLink(props: Record<string, unknown>): void {
  window.utag?.link(props);
}

/**
 * Constructs the proper GA4 page-view object depending on state, router location, and flags
 * @param state
 * @param location
 * @returns - GA4 page-view object
 *
 * @TODO - We no longer use either UA or GA4, and values from both this and the
 * `createTealiumPageViewPayload` helper are combined for `sendPageView`
 *
 * We should get rid of the separate helpers, if we can, and create a new, single
 * helper that includes all of the variables from both and send them
 */
export function createGa4PageViewPayload({
  state,
  location: routerLocation,
  flags,
  custom_page_name,
}: {
  state: AppState;
  location: Location;
  flags: LDFlagSet | undefined;
  custom_page_name?: string;
}): Ga4PageViewDimensions {
  const { beneficiary, language: stateLang } = state;
  const language = getLanguageFromLocationOrState({
    location: routerLocation,
    stateLang,
  });
  const { isOutsideOpenEnrollment } = getYearPartInfo(flags);
  const site_domain = window.location.host;
  const site_environment = getEnvironment();
  const site_section = "mct";
  const content_type = "mct page";
  const content_language = language;
  const logged_in = hasGlobalSession(state) ? "true" : "false";
  const { pathname, search } = routerLocation;
  let page_name = getRouteName(pathname);
  if (custom_page_name) {
    page_name = `${page_name}: ${custom_page_name}`;
  }
  const open_enrollment = !isOutsideOpenEnrollment ? "true" : "false";
  const {
    csr_id,
    meta_data: { is_csr },
  } = state.beneficiary ?? { csr_id: "", meta_data: { is_csr: false } };
  const isCsr = getIsCsrSession({ csr_id, is_csr, pathname, search });
  const isBene =
    beneficiary ||
    beneLandingAndCallbackRoutes.includes(routerLocation.pathname);

  return {
    content_language,
    content_type,
    logged_in,
    open_enrollment,
    page_name,
    role: isCsr
      ? UserRole.CSR
      : isBene
        ? UserRole.AUTH_BENEFICIARY
        : UserRole.ANON_BENEFICIARY,
    site_domain,
    site_environment,
    site_section,
  };
}

/**
 * Constructs the proper tealium page-view object depending on state and TealiumPageViewProps
 *
 * @TODO - We no longer use either UA or GA4, and values from both this and the
 * `createGA4PageViewPayload` helper are combined for `sendPageView`
 *
 * We should get rid of the separate helpers, if we can, and create a new, single
 * helper that includes all of the variables from both and send them
 */
export function createTealiumPageViewPayload({
  props: {
    location: routerLocation,
    mctCurrentPlanYear,
    page_name,
    page_type = "application",
  },
  state: appState,
}: {
  props: TealiumPageViewProps;
  state: AppState;
}): Record<string, unknown> {
  const page = `${window.location.pathname}${window.location.hash.replace(
    /\?.+/,
    ""
  )}`;
  let lis_level = appState.lis || "";
  if (
    appState.futureLis &&
    mctCurrentPlanYear &&
    appState.year !== mctCurrentPlanYear.toString()
  ) {
    lis_level = appState.futureLis;
  }
  const { pathname, search } = routerLocation;
  const {
    csr_id,
    meta_data: { is_csr },
  } = appState.beneficiary ?? { csr_id: "", meta_data: { is_csr: false } };
  const isCsr = getIsCsrSession({ csr_id, is_csr, pathname, search });
  const isBene =
    appState.beneficiary || beneLandingAndCallbackRoutes.includes(pathname);

  const newToMedicare = appState?.beneficiary?.new_to_medicare;
  const newToMedicareStatus =
    newToMedicare === NewToMedicareStatus.YES
      ? "true"
      : newToMedicare === NewToMedicareStatus.NO
        ? "false"
        : "undefined";

  return {
    page,
    page_name,
    page_type,
    count_insurance_carriers:
      appState.searchResultsFilters.insuranceCarriers.length.toString(),
    count_pharmacies: appState.pharmacies.length.toString(),
    count_prescriptions: appState.prescriptions.length.toString(),
    insurance_carriers:
      appState.searchResultsFilters.insuranceCarriers.join("|"),
    lis_level,
    language: window.location.hash.includes("lang=es")
      ? "es"
      : appState.language,
    pharmacy_type: appState.pharmacyType,
    plan_type: appState.planType || "",
    prescriptions_ndc: appState.prescriptions.map(p => p.ndc).join("|"),
    logged_in: appState.beneficiary ? "true" : "false",
    user_type: isCsr
      ? UserType.CSR
      : isBene
        ? UserType.AUTH_BENEFICIARY
        : UserType.ANON_BENEFICIARY,
    view_coverage_wizard: appState.cwCoverage ? "true" : "false",
    new_to_medicare_beneficiary: newToMedicareStatus,
  };
}

/**
 * Used to prevent firing page view events on callback routes
 * @see src/components/ReferrerDetector.tsx (the component responsible for dispatching
 * to send page view events)
 *
 * Manage Prescriptions has 3 "modes," and each is sent as a separate pageview,
 * so this is handled within the `ManagePrescriptionsPage` component
 */
export const shouldSendPageView = (location: Location) =>
  !(
    location.pathname === routes.slsCallback ||
    location.pathname === routes.mbpLandingPage ||
    location.pathname === routes.managePrescriptions
  );

export const useShouldSendPageView = () => {
  const location = useLocation();
  return shouldSendPageView(location);
};

export const sendTealiumPageView = ({
  props: {
    location: routerLocation,
    mctCurrentPlanYear,
    page_name,
    page_type = "application",
  },
  state,
}: {
  props: TealiumPageViewProps;
  state: AppState;
}) => {
  if (!window.utag?.view) {
    return;
  }
  const payload = createTealiumPageViewPayload({
    props: {
      location: routerLocation,
      page_name,
      mctCurrentPlanYear,
      page_type,
    },
    state,
  });

  window.utag.view(payload);
};

/**
 * Creates a virtual page view for GA4 and, if appropriate for the current
 * route/location, UA. Called from the `ReferrerDetector` component to handle
 * triggering a view on every route change
 */
export function sendPageView({
  props,
  state,
}: {
  props: PageViewProps;
  state: AppState;
}): void {
  if (!window.utag?.view) {
    return;
  }
  const {
    location: routerLocation,
    mctCurrentPlanYear,
    page_name,
    page_type = "application",
    custom_page_name,
    ...additionalPageViewProps
  } = props;
  const nonUAPageViewPayload = createGa4PageViewPayload({
    state,
    location: routerLocation,
    flags: state.cachedFlags,
    custom_page_name,
  });
  const uaPageViewPayload = createTealiumPageViewPayload({
    props: {
      location: routerLocation,
      page_name,
      mctCurrentPlanYear,
      page_type,
    },
    state,
  });
  window.utag.view({
    ...uaPageViewPayload,
    ...nonUAPageViewPayload,
    ...additionalPageViewProps,
  });
}

export function sendDrugsManagedAnalyticsEvent(
  prescriptions: PrescriptionDrug[]
) {
  if (!window.utag?.link) {
    return;
  }
  const drugNames = prescriptions.map(p => p.name).join("|");
  const drugTypes = prescriptions
    .map(p => (p.is_generic ? "generic" : "brand"))
    .join("|");

  window.utag.link({
    event_name: "mct_plan_finder_drugs_managed",
    event_category: "MCT",
    event_action: "Find Plans - Confirm Drugs",
    event_label: `${prescriptions.length}|${drugNames}`,
    drug_name: drugNames,
    drug_count: prescriptions.length,
    drug_type: drugTypes,
  });
}

export function sendPrintAnalyticsEvent() {
  if (!window.utag?.link) {
    return;
  }
  window.utag.link({
    event_name: Ga4Event.PRINT,
    link_type: LinkType.LINK_OTHER,
  });
}

/**
 * helper to add parent component props with type-safety
 * @example
 *  <MedicareGovAnchor
      urlKey="prescriptionPaymentPlan"
      {...addParentComponentProps(
        t(m3pModalHeadingTranslationKey),
        "modal"
      )}
    >
*/
export const addParentComponentProps = (
  parent_component_heading: string,
  parent_component_type: ParentComponentType
) => ({
  [PARENT_COMPONENT_HEADING_ATTRIBUTE]: parent_component_heading,
  [PARENT_COMPONENT_TYPE_ATTRIBUTE]: parent_component_type,
});
